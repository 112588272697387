import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Configuration } from '../../../app.constants';

export interface DraggableItem {
  id?: number;
  value: string;
}

@Component({
  selector: 'draggable-options',
  templateUrl: './draggable-options.component.html',
  styleUrls: ['./draggable-options.component.scss'],
})
export class DraggableOptionsComponent implements OnInit {
  @ViewChildren('itemInput') itemInputs: QueryList<ElementRef>;
  @Input() draggable = true;
  @Input() enableDelete = true;
  @Input() label: string = this.config.defaultDraggableComponentValues.defaultLabel;
  @Input() AddButtonText: string = this.config.defaultDraggableComponentValues.defaultAddButtonText;
  @Input() placeHolderText: string = this.config.defaultDraggableComponentValues.defaultplaceHolderText;
  @Input() consumedLength = 0;
  @Input() maxLength = 40;
  @Input() maxChar = 45;
  @Input() items: DraggableItem[] = [];
  @Input() isRequired = false;
  @Input() defaultItem: DraggableItem = { id: 0, value: '' };
  @Input() duplicateErrorMessage = this.config.defaultDraggableComponentValues.errorDuplicateDropdownValue;
  @Input() emptyErrorMessage = this.config.defaultDraggableComponentValues.errorEmptyDropdownValue;
  @Input() specialCharErrorMessage = this.config.defaultDraggableComponentValues.errorSpecialCharacters;
  @Output() updatedItems: EventEmitter<DraggableItem[]> = new EventEmitter();
  @Output() updatedDefaultItem: EventEmitter<DraggableItem> = new EventEmitter();
  @Output() dropdownErrorStatus = new EventEmitter<{ hasDuplicates: boolean, hasSpecialChars: boolean, isEmpty: boolean }>();
  isDuplicate = false;
  checkedIndex: number | null = null;
  showEmptyError = false;
  showSpecialCharError: boolean[] = [];

  constructor(private readonly config: Configuration) { }

  ngOnInit() {
    this.label = this.label || this.config.defaultDraggableComponentValues.defaultLabel;
    this.AddButtonText = this.AddButtonText || this.config.defaultDraggableComponentValues.defaultAddButtonText;
    this.placeHolderText = this.placeHolderText || this.config.defaultDraggableComponentValues.defaultplaceHolderText;
    this.duplicateErrorMessage = this.duplicateErrorMessage || this.config.defaultDraggableComponentValues.errorDuplicateDropdownValue;
    this.emptyErrorMessage = this.emptyErrorMessage || this.config.defaultDraggableComponentValues.errorEmptyDropdownValue;
    this.specialCharErrorMessage = this.specialCharErrorMessage || this.config.defaultDraggableComponentValues.errorSpecialCharacters;

    if (this.items.length === 0) {
      this.items.push({ id: 0, value: '' });
    }

    this.setCheckedIndex();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.showEmptyError = false;
      this.isDuplicate = false;
      this.showSpecialCharError = [];
      this.setCheckedIndex();
    }
    if ((changes.defaultItem && changes.isRequired) || changes.isRequired) {
      this.setCheckedIndex(true);
    }
    else if (changes.defaultItem) {
      this.setCheckedIndex();
    }
  }

  ngAfterViewInit() {
    this.itemInputs.changes.subscribe((inputs: QueryList<ElementRef>) => {
      if (inputs.length) {
        const input = inputs.last.nativeElement;
        input.focus();
      }
    });
  }

  setCheckedIndex(initial = false) {
    let defaultItemIndex = this.items.findIndex(item => item.value === this.defaultItem?.value && item.id === this.defaultItem?.id);

    if (defaultItemIndex !== -1) {
      this.checkedIndex = defaultItemIndex;
    } else {
      if (initial) {
        this.checkedIndex = 0;
        if (this.items[0]) {
          this.items[0].value = this.items[0]?.value || '';
        } else {
          this.items[0] = { value: '' };
        }
        this.defaultItem = this.items[0];
      }
    }

    if (this.isRequired && this.checkedIndex !== null) {
      this.updatedDefaultItem.emit(this.items[this.checkedIndex]);
    }
  }

  onDrop(event: CdkDragDrop<DraggableItem[]>) {
    const prevIndex = event.previousIndex;
    const currIndex = event.currentIndex;

    moveItemInArray(this.items, prevIndex, currIndex);

    if (this.checkedIndex === prevIndex) {
      this.checkedIndex = currIndex;
    } else if (this.checkedIndex > prevIndex && this.checkedIndex <= currIndex) {
      this.checkedIndex -= 1;
    } else if (this.checkedIndex < prevIndex && this.checkedIndex >= currIndex) {
      this.checkedIndex += 1;
    }
    this.updateItemsWithoutEmptyValue(this.items);
    this.checkAllErrors();
  }

  onInputChange(value: string, index: number) {
    const specialCharPattern = /[^a-zA-Z0-9 ]/g;

    if (specialCharPattern.test(value)) {
      this.showSpecialCharError[index] = true;
    } else {
      this.showSpecialCharError[index] = false;
    }

    this.items[index].value = value.trim();
    this.updateItemsWithoutEmptyValue(this.items);
    this.checkForDuplicates(this.items);

    if (this.isRequired && index === this.checkedIndex) {
      this.updatedDefaultItem.emit(this.items[this.checkedIndex]);
    }

    this.checkAllErrors();
  }

  updateItemsWithoutEmptyValue(arr: DraggableItem[]) {
    const filteredArray = arr.filter(item => item.value.trim());
    this.updatedItems.emit(filteredArray);
  }

  removeItem(index: number): void {
    if (this.items.length === 1) {
      this.items[0].value = '';
      this.items[0].id = 0; // id=0 is used to identify it as newly created item
      this.showEmptyError = true;
      this.showSpecialCharError[0] = false;
      this.checkedIndex = null;
      this.defaultItem = { id: null, value: '' };
      this.updatedDefaultItem.emit(this.defaultItem);
    } else {
      if (this.checkedIndex === index) {
        this.checkedIndex = null;
        this.defaultItem = { id: null, value: '' };
        this.updatedDefaultItem.emit(this.defaultItem);
      } else if (this.checkedIndex !== null && this.checkedIndex > index) {
        this.checkedIndex--;
      }

      this.items.splice(index, 1);
      this.showSpecialCharError.splice(index, 1);
      this.showEmptyError = this.items.every(item => item.value.trim() === '');
    }

    this.updateItemsWithoutEmptyValue(this.items);
    this.checkForDuplicates(this.items);
    this.checkAllErrors();
  }

  generateRandomNegativeNumber() {
    let randomNumber = Math.floor(Math.random() * 100) + 1;
    return -randomNumber;
  }

  addItem() {
    this.items.push({ id: this.generateRandomNegativeNumber(), value: '' });
    this.showSpecialCharError.push(false);
    this.checkAllErrors();
  }

  hasSpecialCharErrors(): boolean {
    return this.showSpecialCharError.some(error => error);
  }

  trackByIndex(index: number, item: DraggableItem): number {
    return index;
  }

  onRadioChange(index: number): void {
    this.checkedIndex = index;
    this.defaultItem = this.items[index];
    this.updatedDefaultItem.emit(this.items[index]);
  }

  checkForDuplicates(arr: DraggableItem[]): void {
    const nonEmptyItems = arr.filter(item => item.value.trim() !== '');

    if (nonEmptyItems.length > 1) {
      const lowerCaseItems = nonEmptyItems.map(item => item.value.toLowerCase());
      let uniqueItems = new Set(lowerCaseItems);
      this.isDuplicate = uniqueItems.size !== nonEmptyItems.length;
    } else {
      this.isDuplicate = false;
    }
  }

  checkAllErrors() {
    const hasSpecialChars = this.showSpecialCharError.some(error => error);
    const hasDuplicates = this.isDuplicate;
    const isEmpty = this.items.some(item => item.value.trim() === '');
    this.showEmptyError = isEmpty;

    this.dropdownErrorStatus.emit({ hasDuplicates, hasSpecialChars, isEmpty });
  }

  checkForEmptyItem(arr: DraggableItem[]): boolean {
    return arr.some(item => item.value.trim() === '');
  }

  checkForMaxLength(): boolean {
    return this.items.length >= this.maxLength;
  }

  getNonEmptyItemCount(): number {
    return this.items.filter(item => typeof item.value === 'string' && item.value.trim() !== '').length;
  }
}
