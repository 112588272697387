import { Component, Input, Output, EventEmitter, inject, ViewChild, OnInit, SimpleChange } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CampaignDetailsState } from 'app/budget-object-details/types/budget-object-details-state.interface';
import { HierarchySelectConfig, HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { MatSelectChange } from '@angular/material/select';
import { SelectOption } from '@shared/types/select-option.interface';
import { BudgetObjectType } from '@shared/types/budget-object-type.interface';
import { Observable } from 'rxjs';
import { GLCode, Vendor } from '@shared/services/company-data.service';
import { TagControlEvent, TagsControlComponent } from '@shared/components/tags-control/tags-control.component';
import { AutocompleteItem } from '@shared/types/autocomplete-item.type';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { Attachment } from '@shared/types/attachment.interface';
import { BudgetObjectAttachmentsService } from 'app/budget-object-details/services/budget-object-attachments.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';
import { CurrencyDO } from '@shared/services/backend/company-currency.service';
import { TaskListChangeEvent } from 'app/budget-object-details/components/tasks-list/tasks-list.component';
import { DrawerFormFields } from 'app/budget-object-details/components/containers/details-drawer-form';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CustomFieldStateConfig, CustomFieldsService } from '../../custom-fields/custom-field.service';
import { capitalizeString } from '@shared/utils/common.utils';

@Component({
  selector: 'campaign-drawer-details-form',
  templateUrl: './campaign-details-form.component.html',
  styleUrls: ['./campaign-details-form.component.scss']
})
export class CampaignDetailsFormComponent implements OnInit {
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);
  private readonly customFieldsService = inject(CustomFieldsService);

  @Input() isReadOnlyMode: boolean;
  @Input() objectType: string;
  @Input() currentCompanyUser: CompanyUserDO;
  @Input() budgetTodayDate: Date;
  @Input() formData: FormGroup;
  @Input() currentState: CampaignDetailsState;
  @Input() parentCampaignIsCommitted: boolean;
  @Input() companyCurrencyCode: string;
  @Input() currencyList: CurrencyDO[];
  @Input() attachmentsManager: BudgetObjectAttachmentsService;
  @Input() glCodes: GLCode[];
  @Input() vendors: Vendor[];
  @Input() tagsAutocompleteItems: AutocompleteItem[];
  @Input() locationItems: HierarchySelectItem[];
  @Input() getVendorName: string;
  @Input() maxVendorNameLength: number;
  @Input() autocompleteVendors: Observable<Vendor[]>;
  @Input() selectSegmentsConfig: HierarchySelectConfig;
  @Input() allowedSegmentSelectItems: HierarchySelectItem[];
  @Input() objectTypes: BudgetObjectType[];
  @Input() unsavedCustomTypeId: number;
  @Input() isCustomTypeEntering: boolean;
  @Input() ownerOptions: SelectOption[];
  @Input() selectedSegmentId: number;
  @Input() isChildCampaignCreation: boolean;
  @Input() companyId: number;
  @Input() objectId: number;
  @Input() isCustomFieldsEnabledForCG: boolean;
  @Input() set resetCustomFieldsFormGroup (val: boolean) {
    if (typeof val === 'boolean' && val) {
      this.initialDropdownState = this.form.getRawValue();
    }
  }
  @Input() resetFormAndFetchCustomField: boolean;

  @Output() handleOwnerChange = new EventEmitter<MatSelectChange>();
  @Output() handleTypeChange = new EventEmitter<void>();
  @Output() handleCustomTypeChange = new EventEmitter<void>();
  @Output() handleSegmentChanged = new EventEmitter<HierarchySelectItem>();
  @Output() handleVendorChange = new EventEmitter<void>();
  @Output() handleParentSelectionChange = new EventEmitter<string>();
  @Output() objectCurrencyChanged = new EventEmitter<string>();
  @Output() handleFileAttached = new EventEmitter<Attachment>();
  @Output() handleFileDelete = new EventEmitter<Attachment>();
  @Output() handleFileDownload = new EventEmitter<Attachment>();
  @Output() handleTasksUpdate = new EventEmitter<TaskListChangeEvent>();
  @Output() syncUnsavedChangesFlag = new EventEmitter<boolean>();
  @Output() syncCustomFieldsUsageChanges = new EventEmitter<boolean>();
  @Output() syncCustomFieldsFormValidity = new EventEmitter<boolean>();
  @Output() syncCustomFieldsFormGroup = new EventEmitter<FormGroup>();
  @Output() customFieldsStateDiff = new EventEmitter<any>();
  @Output() onAmountClick = new EventEmitter<void>();

  @ViewChild('tagsControl') tagsControl: TagsControlComponent;

  customFieldConfigs: CustomFieldStateConfig[] = [];
  
  form: FormGroup;
  initialDropdownState: Record<string, any[]> = {};
  customFieldsPayload: Record<string, string[]> = {};

  constructor(private formBuilder: FormBuilder){}
  
  ngOnInit() {}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if(
      (changes.isCustomFieldsEnabledForCG && changes.isCustomFieldsEnabledForCG.currentValue)
      ||
      (this.isCustomFieldsEnabledForCG && changes.resetFormAndFetchCustomField && changes.resetFormAndFetchCustomField.currentValue)
    ) {
      this.fetchCustomFieldsForCG();
      console.log("Status ==> ", this.isCustomFieldsEnabledForCG)
    }  
  } 

  protected readonly DrawerFormFields = DrawerFormFields;
  protected readonly currencyTooltip ="In order to save this campaign in a different currency, please save it and then edit the currency";
  protected currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: true, prefix: '' };

  protected minEndDate = null;

  public onDropdownStateChanged(selectedValue: any, index: number) {
    // Handle state change here
    const controlName = 'dropdown_' + index;
    this.form.get(controlName).setValue(selectedValue);

    let diffMap = this.customFieldsService.getStateDiff(this.initialDropdownState, this.form.getRawValue());
    this.customFieldsPayload = this.customFieldsService.getSelectedOptionsIdPayloadForCF(diffMap, this.customFieldConfigs);
  
  console.log("payload : ", this.customFieldsPayload);

    if(Object.keys(diffMap).length > 0){
      this.syncCustomFieldsUsageChanges.emit(true);
    }else {
      this.syncCustomFieldsUsageChanges.emit(false);
    }

    if( this.form.valid && Object.keys(diffMap).length > 0){
      this.syncUnsavedChangesFlag.emit(true);
      this.customFieldsStateDiff.emit(this.customFieldsPayload);
    }else {
      this.syncUnsavedChangesFlag.emit();
    }
    this.syncCustomFieldsFormValidity.emit(this.form.valid);
    console.log("diffMap: ", diffMap);
  }

  public initializeCustomFieldsForm() {
    const controlsConfig = this.customFieldConfigs.reduce((acc, config, index) => {
      let defaultValue = config.defaultValue !== undefined ? config.defaultValue : null;
      let selectedValue: any = config.selectedValue !== undefined ? config.selectedValue : this.objectId ? undefined : defaultValue;
     
      if(config.isMultiSelect) {
        selectedValue = config.selectedValue.length ? config.selectedValue : this.objectId ? [] : defaultValue;
      }

      acc['dropdown_' + index] = [selectedValue, config.required ? Validators.required : null];
      return acc;
    }, {});
    this.form = this.formBuilder.group(controlsConfig);
    this.initialDropdownState = this.form.getRawValue();
    this.syncCustomFieldsFormValidity.emit(this.form.valid);
    if(!this.objectId){
      this.initialDropdownState = this.customFieldsService.initDefaultCustomFieldsFormData(this.objectId, this.form);
      let diffMap = this.customFieldsService.getStateDiff(this.initialDropdownState, this.form.getRawValue());
      this.customFieldsPayload = this.customFieldsService.getSelectedOptionsIdPayloadForCF(diffMap, this.customFieldConfigs);
      this.customFieldsStateDiff.emit(this.customFieldsPayload);
      console.log(diffMap);
    }
    
    this.syncCustomFieldsFormGroup.emit(this.form);
  }

  public fetchCustomFieldsForCG() {
    if(this.form) { 
      this.form.reset();
    }
    this.customFieldsService.fetchDropdownOptions(
      this.companyId, 
      this.objectId,
      capitalizeString(this.objectType)
    )
    .subscribe(data => { 
      console.log(data);
      this.customFieldConfigs = data;
      this.initializeCustomFieldsForm();
     });
  }

  protected onChangeAmountState(value: string): void {
    this.formData.get(DrawerFormFields.amountStatus).setValue(value);
    this.syncUnsavedChangesFlag.emit();
  }

  protected get glCodeActiveName(): string {
    const glId = this.formData.controls[DrawerFormFields.glCode]?.value;
    const glItem = this.glCodes?.find(code => code.id === glId);
    return glItem ? glItem.name : '';
  }

  protected createTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.createTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected addTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.addTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected removeTag(tag: TagControlEvent): void {
    this.budgetObjectActionsShared.removeTag(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag.emit();
  }

  protected get segmentControl(): AbstractControl {
    return this.formData.get(DrawerFormFields.segment);
  }

  protected onHandleStartDateChange(e): void {
    this.minEndDate = e;
    this.formData.controls.startDate.setValue(e);
  }

  protected onHandleEndDateChange(e): void {
    this.formData.controls.endDate.setValue(e);
  }

}
