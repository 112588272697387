import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'disabled-flag-mat-select',
  templateUrl: './disabled-flag-mat-select.component.html',
  styleUrls: ['./disabled-flag-mat-select.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class DisabledFlagMatSelectComponent implements OnChanges {

  @Input() labelName: string;
  @Input() isRequired: boolean;
  @Input() formData: UntypedFormGroup;
  @Input() optionTitle: Array<string>;
  @Input() control: string;
  @Input() addNullOption: boolean;
  @Input() nullOptionTitle: string;
  @Input() value: number;
  @Input() itemList: any[];
  @Input() hasError: boolean;
  @Input() errorString: string;
  @Output() onValueChange = new EventEmitter();
  disabledItemData: any;
  optionsList: any[];

  constructor() { }

  ngOnChanges(): void {
    this.optionsList = this.itemList.filter(item => item.isEnabled || item.is_enabled);
    this.disabledItemData = this.getDisabledFlag(this.value, this.itemList);
  }

  getDisabledFlag(itemValue, itemList: any) {
    let placeholderText, selectedItem;
    if (itemValue != null && itemList.length > 0) {
      selectedItem = itemList.filter(item => item.id === itemValue);
      placeholderText = selectedItem.length > 0 ? (selectedItem[0].isEnabled || selectedItem[0].is_enabled) ? '' : `${selectedItem[0].name} (Disabled)` : '';
    }
    return { placeholderText, itemValue }
  }

  get triggerName(): string {
    const triggerItem = this.optionsList.find(code => code.id === this.value);
    return triggerItem ? triggerItem.name : null;
  }

  buildOptionTitle(item): string {
    let titleMapper = [];
    this.optionTitle.forEach(option => {
      if (item[option]) {
        titleMapper.push(item[option])
      }
    })
    return titleMapper.join(' - ');
  }

  valueChanged(e) {
    this.onValueChange.emit({ value: e.value, controlName: this.control });
  }

}
