<article class="root-layout">
	<navigation></navigation>
	<div class="right-side">
		<div class="box-topBar">
			<h4 class="box-pageHeadingBar">Planful | Account Management</h4>
      <a class="profile-component ml-a" [routerLink]="['', { outlets: { primary: 'user', details: null } }]" queryParamsHandling="preserve">
        <profile-menu theme='blue' [disabled]="true" ></profile-menu>
      </a>
    </div>
		<div class="right-side-content fd-column">
      <div class="d-flex jc-between full-width">
        <div class="d-flex search-main-block">
          <div class="box-searchManageTeam">
            <input type="text" placeholder="Search" [(ngModel)]="search" (keyup.enter)="getCompaniesList(filter_by)">
            <button class="btn-searchTeam" type="button" (click)="getCompaniesList(filter_by)"></button>
          </div>
          <ul class="list-allCategory">
            <li class="list-selectCategory">
              <angular2-multiselect
                id="planTypeDropdown"
                [data]="filter_data"
                [settings]="planTypeDropdownSettings"
                [(ngModel)]="selectedFilter"
                (onSelect)="onSelectFilter($event, 'click')"
                (onDeSelect)="onDeSelectFilter($event)">
              </angular2-multiselect>
            </li>
          </ul>
        </div>
        <ul class="search_block">
          <li>
            <button class="btn-addCampaignView" type="button" title="" (click)="utilityService.setNoRegistrationFlag(); userManager.logout(configuration.ROUTING_CONSTANTS.REGISTER);">Register Free Account</button>
          </li>
          <li>
            <actions-dropdown
              [title]="'Account Actions'"
              [optionsList]="actionsOptionsForDisplay"
              [disableDropdown]="disableEditAll"
              (toggleActionsDropDown)="toggleActionsDropDown()"
            ></actions-dropdown>
          </li>
        </ul>
      </div>
      <div class="box-tableManageExpense">
        <div class="box-tableManageExpenseInn" #table>
          <table class="table-superAdminCompany table-evenOdd">
            <thead>
            <tr>
              <th>
                <mat-checkbox class="select-all-btn"
                  color="primary"
                  [checked]="isAllCheckedCompany"
                  (change)="checkAll($event, companyData.length)">
                </mat-checkbox>
              </th>
              <th>Account <span class="icon-sortingTable" (click)="companySort('name')"></span></th>
              <th>Owner <span class="icon-sortingTable" (click)="companySort('owner')"></span></th>
              <th>Active Budgets<span class="icon-sortingTable" (click)="companySort('budget_count')"></span></th>
              <th>Created <span class="icon-sortingTable" (click)="companySort('crd')"></span></th>
              <th>Updated <span class="icon-sortingTable" (click)="companySort('upd')"></span></th>
              <th>Acct Status</th>
              <th class="adopted">Adopted <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Adopted)"></span></th>
              <th>Comp</th>
              <th>Non-Reporting</th>
              <th>Salesforce <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Salesforce)"></span></th>
              <th>Google Ads <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.GoogleAds)"></span></th>
              <th>LinkedIn Ads <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.LinkedinAds)"></span></th>
              <th>Facebook Ads <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.FacebookAds)"></span></th>
              <th>SSO <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.SSO)"></span></th>
              <th>Budgie <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Budgie)"></span></th>
              <th>HubSpot <span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyIntegrationSources.Hubspot)"></span></th>
              <th>Default Timeframe Suppression<span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyProperties.DefaultForSuppressTimeframeAllocations)"></span></th>
              <th>SaaSOptics ID<span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyProperties.SaasOpticsId)"></span></th>
              <th>Salesforce Tenant ID<span class="icon-sortingTable" (click)="sortCompaniesLocally(CompanyProperties.SalesforceTenantId, compareTypes.Alphabetical)"></span></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let company of companyData; let i = index; trackBy: trackCompanyFn">
              <td>
                <mat-checkbox
                  name="sizecb[]"
                  color="primary"
                  [checked]="checks[i]"
                  (change)="checkCompany($event.checked, i, companyData.length)">
                </mat-checkbox>
              </td>
              <td><span class="table-contOverflow" title="{{company.name}}">{{company.name}}</span></td>
              <td><span class="table-contOverflow" title="{{company.owner}}">{{company.owner}}</span></td>
              <td>{{company.budget_count}}</td>
              <td>{{company.crd}}</td>
              <td>{{company.upd}}</td>
              <td>{{ company.status == 'Active' ? 'Active' : '' }}</td>
              <td class="budgie-cell">{{ company.adopted ? 'Yes' : 'No' }}</td>
              <td>{{ company.unlimited ? 'True' : '' }}</td>
              <td>{{ company.non_reporting ? 'Non-Reporting' : '' }}</td>
              <td class="salesforce-cell">{{ company.salesforce ? 'Yes' : 'No' }}</td>
              <td>{{ company.google_ads ? 'Yes' : 'No' }}</td>
              <td>{{ company.linkedin_ads ? 'Yes' : 'No' }}</td>
              <td>{{ company.facebook_ads ? 'Yes' : 'No' }}</td>
              <td class="sso-cell">{{ company.sso_domain }}</td>
              <td class="budgie-cell">{{ company.budgie ? 'Yes' : 'No' }}</td>
              <td class="hubspot-cell">{{ company.hubspot ? 'Yes' : 'No' }}</td>
              <td>{{ company.default_for_suppress_timeframe_allocations ? 'Yes' : 'No' }}</td>
              <td>{{ company.saas_optics_customer_id }}</td>
              <td>{{ company.salesforce_tenant_id }}</td>
            </tr>
            </tbody>
            <tfoot *ngIf="companyData.length == 0">
            <tr>
              <td colspan="19" class="td-expenseNotFound">
                <span>{{validations.ValiditionMessages.NO_RESULT_FOUND}}</span>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
		</div>
	</div>
</article>
