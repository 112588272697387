import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, ComponentRef, ElementRef,
  EventEmitter,
  inject,
  Input, NgZone,
  OnDestroy,
  OnInit,
  Output, ViewChild
} from '@angular/core';
import { ObjectsIconConfig } from 'app/app.constants';
import {
  BudgetTimeframeBrief,
  CreateCegItemTemplateEvent,
  CreateOverlayEvent,
  ManageCegDataMode,
  ManageCegTableActionDataSource,
  ManageCegTableActionEvent, ManageCegTableMenuAction,
  ManageCegTableRow, ManageCegTableRowPerformance,
  ManageCegTableSelectionState, ManageCegViewMode,
  ManageTableBudgetColumn,
  ManageTableBudgetColumnName,
  ManageTableBudgetColumnsVisibility,
  ManageTablePerformanceColumn,
  ManageTablePerformanceColumnName,
  ManageTableRowTypeLabel,
  ManageTableTotalValues,
  ManageTableViewConfig,
  PerformanceColumnData,
  PresentationTimeframe,
  SegmentBreakdownConfig,
  TotalsSidebarState
} from '@manage-ceg/types/manage-ceg-page.types';
import { BehaviorSubject, merge, Subject } from 'rxjs';
import { RecordInteractionService } from '@manage-ceg/services/record-interaction.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CheckboxValue } from '@shared/enums/checkbox-value.enum';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ManageCegTableHelpers } from '@manage-ceg/services/manage-ceg-table-helpers';
import { ManageCegAllocationService } from '@manage-ceg/services/manage-ceg-allocation.service';
import { BudgetAllocationActionsService } from '../../../budget-allocation/services/budget-allocation-actions.service';
import { ManageCegPageRowIntersectionService } from '@manage-ceg/services/manage-ceg-page-row-intersection.service';
import { ManageCegTableDataService } from '@manage-ceg/services/manage-ceg-table-data.service';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { RecordContextActionsComponent } from '@manage-ceg/components/manage-table-ceg/components/record-context-actions/record-context-actions.component';
import { FullHierarchyTreeComponent } from '@shared/components/full-hierarchy-tree/full-hierarchy-tree.component';
import { messages, objectPlaceholderName } from '../../../budget-object-details/messages';
import { UtilityService } from '@shared/services/utility.service';
import { ManageCegPageService } from '@manage-ceg/services/manage-ceg-page.service';
import { BudgetSegmentAccess } from '@shared/types/segment.interface';
import { SharedCostRule } from '@shared/types/shared-cost-rule.interface';
import { ManageTableRowType } from '@shared/enums/manage-table-row-type.enum';
import { ManageCegPageModeService } from '@manage-ceg/services/manage-ceg-page-mode.service';
import { DropState } from '@shared/types/droppable-state.type';
import { ManageTableHelpers } from '../../../manage-table/services/manage-table-helpers';
import { HierarchySelectItem } from '@shared/components/hierarchy-select/hierarchy-select.types';
import { ManageCegDataValidationService } from '@manage-ceg/services/manage-ceg-data-validation.service';
import { TableContentShadowsBaseComponent } from '@shared/directives/TableContentShadowsBase';
import { HierarchyViewMode } from '@spending/types/expense-page.type';
import { FilterName } from 'app/header-navigation/components/filters/filters.interface';
import { FilterManagementService } from 'app/header-navigation/components/filters/filter-services/filter-management.service';

@Component({
  selector: 'manage-table-ceg',
  templateUrl: './manage-table-ceg.component.html',
  styleUrls: ['./manage-table-ceg.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ManageTableCegComponent extends TableContentShadowsBaseComponent implements OnInit, OnDestroy {
  private readonly recordInteractionService = inject(RecordInteractionService);
  private readonly actionsManager: BudgetAllocationActionsService<ManageCegTableActionDataSource> = inject(BudgetAllocationActionsService);
  private readonly manageAllocationService = inject(ManageCegAllocationService);
  protected readonly dataValidationService = inject(ManageCegDataValidationService);
  private readonly tableDataService = inject(ManageCegTableDataService);
  private readonly managePageService = inject(ManageCegPageService);
  private readonly rowIntersectionService = inject(ManageCegPageRowIntersectionService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder);
  private readonly overlay = inject(Overlay);
  private readonly utilityService = inject(UtilityService);
  protected readonly viewModeService = inject(ManageCegPageModeService);
  private readonly zone = inject(NgZone);

  @Input() rows: ManageCegTableRow[];
  @Input() performanceColumnData: PerformanceColumnData;
  @Input() allRootRowsLoaded: boolean;
  @Input() grandTotalBudget: Record<PresentationTimeframe, ManageTableTotalValues>;
  @Input() grandTotalPerformance: ManageCegTableRowPerformance;
  @Input() iconByRowType: ObjectsIconConfig;
  @Input() routeActionByRowType: Record<ManageTableRowType, (number) => void>;

  @Input() isLoading: boolean;
  @Input() isFilteredMode: boolean;
  @Input() hasHiddenHierarchy = false;
  @Input() isAdmin: boolean;
  @Input() editPermission: boolean;
  @Input() timeframes: BudgetTimeframeBrief[];
  @Input() totalsSidebarState: TotalsSidebarState;
  @Input() objectTypeNameMap: Record<number, string>;
  @Input() remainingBudget: number;
  @Input() budgetColumns: ManageTableBudgetColumn[];

  @Output() totalsSidebarStateChanged = new EventEmitter<TotalsSidebarState>();
  @Output() onCreateItemFromTemplate = new EventEmitter<string>();
  @Output() onCreateNewItemTemplate = new EventEmitter<CreateCegItemTemplateEvent>();

  protected grandTotalForActiveTimeframes: ManageTableTotalValues;
  protected selectedRecords: number[] = [];
  protected currentDataMode: ManageCegDataMode;
  protected SelectionValue = CheckboxValue;
  protected budgetMode: boolean;
  protected performanceMode: boolean;
  protected maxShowMoreButtonTextLength = 30;

  protected ManageTableRowType = ManageTableRowType;

  private readonly fullHierarchyHovered$ = new BehaviorSubject<boolean>(false);
  private overlayRef: OverlayRef;
  private readonly closeOverlay$ = new Subject<void>();
  protected contextMenuTargetId: string;
  private actionProvider = {
    [ManageCegTableMenuAction.Duplicate]: this.onClone.bind(this),
    [ManageCegTableMenuAction.MoveTo]: this.onMoveTo.bind(this),
    [ManageCegTableMenuAction.Close]: this.onClose.bind(this),
    [ManageCegTableMenuAction.Reopen]: this.onReopen.bind(this),
    [ManageCegTableMenuAction.Delete]: this.onDelete.bind(this),
    [ManageCegTableMenuAction.CopyId]: this.onCopyId.bind(this),
  };



  protected performanceColumns: ManageTablePerformanceColumn[] = [
    { id: ManageTablePerformanceColumnName.TargetReturn, label: 'Target Return' },
    { id: ManageTablePerformanceColumnName.CurrentReturn, label: 'Current Return' },
    { id: ManageTablePerformanceColumnName.LowForecast, label: 'Low Forecast' },
    { id: ManageTablePerformanceColumnName.HighForecast, label: 'High Forecast' },
    { id: ManageTablePerformanceColumnName.TargetRoi, label: 'Target ROI' },
    { id: ManageTablePerformanceColumnName.CurrentRoi, label: 'Current ROI' },
    { id: ManageTablePerformanceColumnName.Owner, label: 'Owner' },
  ];

  protected budgetColumnsVisibility: ManageTableBudgetColumnsVisibility = {
    [ManageTableBudgetColumnName.Budget]: true,
    [ManageTableBudgetColumnName.Actual]: false,
    [ManageTableBudgetColumnName.Planned]: false,
    [ManageTableBudgetColumnName.Committed]: false,
    [ManageTableBudgetColumnName.CommittedAndPlanned]: false,
    [ManageTableBudgetColumnName.Available]: false,
  };

  protected segmentBreakdownConfig: SegmentBreakdownConfig = {
    campaignsAndPrograms: false,
    unallocated: false,
    formula: false,
  };

  private readonly filterNameByRowType = {
    [ManageTableRowType.Segment]: FilterName.Segments,
    [ManageTableRowType.Goal]: FilterName.Goals,
    [ManageTableRowType.Campaign]: FilterName.Campaigns,
    [ManageTableRowType.ExpenseGroup]: FilterName.ExpenseBuckets,
  };

  protected showColumnsFormula: boolean;
  protected updateShadows$ = new BehaviorSubject(null);
  private readonly destroy$ = new Subject<void>();
  protected freezeTotalsSidebarAnimations = false;
  private tableBody: ElementRef;
  public dropState = DropState;
  public draggedEntity: ManageCegTableRow;
  public droppableItem: {[key: number]: DropState} = {};
  public dragEnter = null;

  @ViewChild('tableContainer') tableContainer: ElementRef;
  @ViewChild('tableBodyRef') set tableBodyRef(tableBody: ElementRef) {
    if (!tableBody) {
      return;
    }
    this.tableBody = tableBody;
    if (this.rows?.length) {
      this.rowIntersectionService.initRowsListener(this.tableContainer, tableBody);
    } else {
      this.rowIntersectionService.destroyRowsListener();
    }
  }

  @Input() set tableViewConfig(config: ManageTableViewConfig) {
    if (!config) {
      return;
    }
    this.segmentBreakdownConfig = config.segmentBreakdownData;
    this.showColumnsFormula = config.columnsFormula;
    this.budgetColumnsVisibility = config.columns;
  }

  @Input() set dataMode(mode: ManageCegDataMode) {
    this.currentDataMode = mode;
    this.budgetMode = mode === ManageCegDataMode.Budget;
    this.performanceMode = mode === ManageCegDataMode.Performance;
  }

  ngOnInit() {
    this.fullHierarchyHovered$.pipe(
      filter(r => !r),
      takeUntil(this.destroy$)
    ).subscribe(() => this.hideHierarchyTooltip());

    this.recordInteractionService.selectionChanged$
      .pipe(takeUntil(this.destroy$))
      .subscribe(selection => this.countSelectedRecords(selection));

    merge(
      this.tableDataService.refreshTable$,
      this.rowIntersectionService.refreshTable$
    ).pipe(takeUntil(this.destroy$))
      .subscribe(() => this.cdr.markForCheck());

    this.tableDataService.grandTotalForActiveTimeframes$.pipe(
      takeUntil(this.destroy$),
      tap(grandTotalForActiveTimeframes => {
        this.grandTotalForActiveTimeframes = grandTotalForActiveTimeframes
      }),
    ).subscribe();
  }

  private countSelectedRecords(selection: ManageCegTableSelectionState): void {
    const { campaigns, expGroups, goals } = ManageCegTableHelpers.getBulkTargetsFromSelection(selection);
    this.selectedRecords = [...campaigns, ...expGroups, ...goals];
  }

  public handleTableResize($event: DOMRectReadOnly): void {
    setTimeout(() => {
      this.updateShadows$.next(null);
    }, 700)
  }

  protected handleNameClick(record: ManageCegTableRow): void {
    this.routeActionByRowType[record.type]?.(record.objectId);
  }

  public handleEntityOnDragStart(record: ManageCegTableRow): void {
    this.zone.run(() => {
      this.draggedEntity = record;
    });
  }

  public handleEntityOnDragEnd(): void {
    this.zone.run(() => {
      this.dragEnter = null;
      this.draggedEntity = null;
    });
  }

  private onClone(record: ManageCegTableRow): void {
    this.managePageService.duplicateItem(record);
  }

  private onMoveTo(record: ManageCegTableRow): void {
    this.recordInteractionService.implicitSelect(record);
    this.managePageService.openHierarchySelection(record);
  }

  private onClose(record: ManageCegTableRow): void {
    this.managePageService.closeItems(record);
  }

  private onReopen(record: ManageCegTableRow): void {
    this.managePageService.reopenItems(record);
  }

  private onDelete(record: ManageCegTableRow): void {
    this.managePageService.deleteItems(record);
  }

  private onCopyId(record: ManageCegTableRow): void {
    navigator.clipboard.writeText(record.externalId).then(() =>
        this.utilityService.showToast({
          Message: messages.COPY_OBJECT_ID_SUCCESS_MSG.replace(objectPlaceholderName, ManageTableRowTypeLabel[record.type] + ' ID')
        }),
      () => this.utilityService.showToast({ Message: messages.COPY_OBJECT_ID_ERROR_MSG })
    );
  }

  protected createNewItemTemplate(record: ManageCegTableRow, index: number): void {
    this.onCreateNewItemTemplate.next({
      contextRow: record,
      position: index,
    });
  }

  protected handlePerformanceClick(metricId: number): void {
    if (metricId) {
      this.managePageService.openCampaignMetricDetails(metricId);
    }
  }

  protected get segments(): BudgetSegmentAccess[] {
    return this.managePageService.segments;
  }

  public get sharedCostRules(): SharedCostRule[] {
    return this.managePageService.sharedCostRules;
  }

  protected get cellsVisibilityState(): Record<string, boolean> {
    return this.rowIntersectionService.cellsVisibilityState;
  }

  public get selectionState(): ManageCegTableSelectionState {
    return this.recordInteractionService.selectionState;
  }

  public handleSelectAllChange($event: MatCheckboxChange): void {
    this.recordInteractionService.handleSelectAllChange(this.rows, $event.checked);
  }

  public handleSelectionChange(record: ManageCegTableRow, value: boolean): void {
    this.recordInteractionService.handleSelection(record, value);
  }

  public get togglingState(): Record<string, boolean> {
    return this.recordInteractionService.togglingState;
  }

  public handleToggleChange(record: ManageCegTableRow, value: boolean): void {
    this.disableTotalsSidebarAnimations();
    this.recordInteractionService.handleToggleChange(record, value);
  }

  protected loadNextChunk(record: ManageCegTableRow): void {
    this.disableTotalsSidebarAnimations();
    this.recordInteractionService.loadNextChunk(record);
  }

  public handleSegmentAllocationChange($event: ManageCegTableActionEvent): void {
    this.manageAllocationService.handleSegmentAllocationChange($event.dataSource, $event.amount);
  }

  public handleAllocationChange($event: ManageCegTableActionEvent): void {
    const { amount, dataSource } = $event;
    this.manageAllocationService.handleAllocationChange(dataSource, amount);
  }

  public handleDoubleClickAction($event: ManageCegTableActionEvent): void {
    const { gestureEvent, dataSource } = $event;
    if (dataSource?.record?.type === ManageTableRowType.Segment) {
      this.manageAllocationService.handleSegmentDoubleClick(dataSource, gestureEvent);
      return;
    }
    this.manageAllocationService.handleDoubleClick(dataSource, gestureEvent);
  }

  public handleDragStartAction($event: ManageCegTableActionEvent): void {
    this.actionsManager.trackDragStart($event.dataSource, $event.gestureEvent);
    this.dataValidationService.setDraggedRecord($event.dataSource.record);
  }

  public handleDropAction($event: ManageCegTableActionEvent): void {
    const { gestureEvent, dataSource } = $event;
    if (dataSource?.record?.type === ManageTableRowType.Segment) {
      this.manageAllocationService.handleSegmentDrop(dataSource, gestureEvent);
      return;
    }
    this.manageAllocationService.handleDrop(dataSource, gestureEvent);
  }

  public handleDragEndAction(): void {
    this.actionsManager.trackDragEnd();
  }

  protected disableTotalsSidebarAnimations(): void {
    // freeze slide animation for totals-sidebar, in time expanding/collapsing child items
    this.freezeTotalsSidebarAnimations = true;
    setTimeout(() => {
      this.freezeTotalsSidebarAnimations = false;
    }, 1000);
  }

  protected openContextMenu(e: CreateOverlayEvent): void {
    const { record, event } = e;
    this.contextMenuTargetId = record.id;
    this.resetOverlayContainer(false);
    const positionConfig: Partial<ConnectedPosition> = {
      originY: 'bottom',
      overlayY: 'top',
    };
    this.overlayRef = this.overlay.create({
      positionStrategy: this.createPositionStrategy(event.target as HTMLElement, positionConfig),
    });

    const tooltipPortal = new ComponentPortal(RecordContextActionsComponent);
    const tooltipRef: ComponentRef<RecordContextActionsComponent> = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.record = record;
    tooltipRef.instance.closeActions
      .pipe(
        takeUntil(merge(
          this.closeOverlay$,
          this.destroy$,
        ))
      ).subscribe(() => {
      this.closeContextMenu();
    });
    tooltipRef.instance.onActionClick
      .pipe(
        takeUntil(merge(
          this.closeOverlay$,
          this.destroy$,
        ))
      ).subscribe((action: ManageCegTableMenuAction) => {
      this.closeContextMenu();
      this.actionProvider[action](record);
    });
  }

  private closeContextMenu(): void {
    this.contextMenuTargetId = null;
    this.resetOverlayContainer(false);
    this.cdr.markForCheck();
  }

  private resetOverlayContainer(delay: boolean): void {
    this.closeOverlay$.next();

    if (!this.overlayRef) {
      return;
    }
    const clearContainer = () => {
      this.overlayRef?.dispose();
      this.overlayRef = null;
    };
    this.overlayRef.detach();
    if (delay) {
      setTimeout(clearContainer, 100);
    } else {
      clearContainer();
    }
  }

  private createPositionStrategy(elementRef: HTMLElement, customConfig: Partial<ConnectedPosition> = {}): PositionStrategy {
    const defaultConfig: Partial<ConnectedPosition> = {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    };
    return this.overlayPositionBuilder
      .flexibleConnectedTo(elementRef)
      .withPositions([{
        ...defaultConfig,
        ...customConfig
      } as ConnectedPosition]);
  }

  private isParent(item: ManageCegTableRow, childId: number): boolean {
    return item?.children.some(child => child.objectId === childId);
  }

  private checkChildrenType(item: ManageCegTableRow): boolean {
    return item?.children.some(child => child.type === ManageTableRowType.Campaign);
  }

  private countMovedItems(movedItems: ManageCegTableRow): number {
    return (movedItems.children || []).length + 1;
  }

  private forbidDrop(parentItem: ManageCegTableRow, childItem: ManageCegTableRow): boolean {
    if (!childItem) {
      return;
    }
    if("campaign_integrated_source" in parentItem && parentItem.campaign_integrated_source){
      return true;
    }
    switch (this.viewModeService.viewMode) {
      case ManageCegViewMode.Goals:
        return (this.checkChildrenType(childItem) && parentItem.type === ManageTableRowType.Campaign)
          || parentItem.type === ManageTableRowType.ExpenseGroup
          || ( parentItem.type === ManageTableRowType.Campaign
            && childItem.type === ManageTableRowType.Campaign
            && parentItem.parentId?.includes(ManageTableRowType.Campaign.toLowerCase())
          )
          || parentItem.id === childItem.parentId
          || childItem.isClosed
          || (ManageTableHelpers.isSegmentlessObject(childItem) && parentItem.type !== ManageTableRowType.Goal);
      case ManageCegViewMode.Segments:
        return (this.checkChildrenType(childItem) && parentItem.type !== ManageTableRowType.Segment)
          || parentItem.type === ManageTableRowType.SegmentGroup
          || (
            parentItem.type === ManageTableRowType.Campaign
            && parentItem.parentId?.includes(ManageTableRowType.Campaign.toLowerCase())
            && childItem.type !== ManageTableRowType.ExpenseGroup
          )
          || parentItem?.objectId === childItem?.segmentId
          || childItem.isClosed
          || parentItem?.id === childItem?.parentId;
      default:
        return this.checkChildrenType(childItem)
          || parentItem?.parentId && childItem.type !== ManageTableRowType.ExpenseGroup
          || childItem.isClosed
          || parentItem?.id === childItem?.parentId
          || ManageTableHelpers.isSegmentlessObject(childItem);
    }
  }

  public get newItemCreationActive(): boolean {
    return this.managePageService.newItemCreationActive;
  }

  public hideHierarchyTooltip(): void {
    setTimeout(() => {
      if (!this.fullHierarchyHovered$.getValue()) {
        this.resetOverlayContainer(true);
      }
    }, 250);
  }

  public showHierarchyTooltip(e: CreateOverlayEvent): void {
    const { record, event } = e;
    this.resetOverlayContainer(false);
    this.overlayRef = this.overlay.create({
      positionStrategy: this.createPositionStrategy(event.target as HTMLElement),
    });

    const tooltipPortal = new ComponentPortal(FullHierarchyTreeComponent);
    const tooltipRef: ComponentRef<FullHierarchyTreeComponent> = this.overlayRef.attach(tooltipPortal);

    tooltipRef.instance.items = record.hierarchyInfo;
    tooltipRef.instance.hoverState$ = this.fullHierarchyHovered$;
    tooltipRef.instance.closeTooltip
      .pipe(
        takeUntil(merge(
          this.closeOverlay$,
          this.destroy$,
        ))
      ).subscribe(() => {
      this.resetOverlayContainer(false);
    });
  }

  public checkDragEntity(dragEntityId: number): boolean {
    return !!dragEntityId && this.dragEnter === dragEntityId;
  }

  public handleDragOver(event: DragEvent, item: ManageCegTableRow): void {
    this.zone.run(() => {
      if (!this.draggedEntity) {
        return;
      }

      const dragOverId = item.objectId || item.id;
      const draggedId = this.draggedEntity.objectId || this.draggedEntity.id;
      this.droppableItem = {};
      this.dragEnter = this.selectedRecords.includes(Number(item.objectId)) ? null : dragOverId;

      if (dragOverId === draggedId || this.isParent(item, this.draggedEntity.objectId)) {
        this.droppableItem[dragOverId] = this.dropState.EMPTY;
        return;
      }

      if (item.isClosed || item.type === ManageTableRowType.ExpenseGroup) {
        this.droppableItem[dragOverId] = this.dropState.FORBID;
        return;
      }

      this.droppableItem[this.dragEnter] = this.forbidDrop(item, this.draggedEntity) ? this.dropState.FORBID : this.dropState.ALLOW;
    });
  }

  public handleEntityOnDrop(record: ManageCegTableRow): void {
    if (!this.draggedEntity) {
      return;
    }
    this.zone.run(() => {
      if (this.draggedEntity?.objectId === record?.objectId
        || this.selectedRecords.includes(record.objectId)
        || this.forbidDrop(record, this.draggedEntity)
      ) {
        return;
      }

      const parentEntity: HierarchySelectItem = {
        id: String(record.itemId),
        title: record.name,
        objectType: record.type,
        objectId: record.objectId,
        segmentData: {
          budgetSegmentId: record.segmentId,
          sharedCostRuleId: record.sharedCostRuleId
        }
      };

      if (this.selectedRecords.length > 1) {
        this.managePageService.moveSelectedItems(parentEntity, this.selectedRecords.length);
      } else {
        const countDropItems = this.countMovedItems(this.draggedEntity);
        this.managePageService.moveItem(this.draggedEntity, parentEntity, countDropItems);
      }

      this.draggedEntity = null;
      this.dragEnter = null;
    });
  }



  openExpenses(row: ManageCegTableRow, timeframeId?: number, withoutParent = false): void {
    const filtersList = {};

    const viewMode = {
      [ManageTableRowType.SegmentGroup]: HierarchyViewMode.Segment,
      [ManageTableRowType.Segment]: HierarchyViewMode.Segment,
      [ManageTableRowType.Campaign]: HierarchyViewMode.Campaign,
      [ManageTableRowType.ExpenseGroup]: HierarchyViewMode.Campaign
    };

    if (timeframeId) {
      filtersList[FilterName.Timeframes] = [timeframeId];
    }

    if (row.type === ManageTableRowType.SegmentGroup) {
      filtersList[FilterName.Segments] = row.children.reduce((idsArr, segment) => {
        idsArr.push(segment.objectId);
        return idsArr;
      }, []);
    } else {
      const filterName = this.filterNameByRowType[row.type];

      if (filterName) {
        filtersList[filterName] = [row.objectId];
      }
    }

    if (withoutParent) {
      filtersList[FilterName.Campaigns] = [FilterManagementService.NOT_SPECIFIED_FILTER_VALUE];
      filtersList[FilterName.ExpenseBuckets] = [FilterManagementService.NOT_SPECIFIED_FILTER_VALUE];
    }

    this.managePageService.openExpenseList(filtersList, viewMode[row.type]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
