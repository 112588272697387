import { Component, inject, Input, ViewChild } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { combineLatest, forkJoin, merge, Observable, of } from 'rxjs';
import { ActivationEnd, Router } from '@angular/router';
import { catchError, debounceTime, filter, finalize, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AppRoutingService, DetailsRouteData } from 'app/shared/services/app-routing.service';
import { BudgetObjectCreationContext } from 'app/budget-object-details/types/details-creation-context.interface';
import { BudgetObjectDetailsComponent } from '../../../types/budget-object-details-component.interface';
import { ExpenseDetailsService } from '../../../services/expense-details.service';
import { ExpenseDetailsState, ObjectDetailsCommonState } from '../../../types/budget-object-details-state.interface';
import { Tag } from 'app/shared/types/tag.interface';
import { BudgetObjectType } from 'app/shared/types/budget-object-type.interface';
import { Budget } from 'app/shared/types/budget.interface';
import { BudgetTimeframe } from 'app/shared/types/timeframe.interface';
import { ProgramDO } from 'app/shared/types/program.interface';
import { AbstractControl, FormBuilder, FormGroup, UntypedFormControl, ValidatorFn, Validators } from '@angular/forms';
import { BudgetObjectTagsService } from '../../../services/budget-object-tags.service';
import { TagControlEvent, TagsControlComponent } from 'app/shared/components/tags-control/tags-control.component';
import { ExpenseDO } from 'app/shared/types/expense.interface';
import { ObjectHierarchy } from '../../object-hierarchy-nav/object-hierarchy-nav.type';
import { StateField } from '@spending/types/expense-page-drawer.type';
import { BudgetObjectActionsBuilder } from '../../../services/budget-object-actions-builder.service';
import { messages, objectPlaceholderName } from '../../../messages';
import { DataValidationService } from 'app/budget-object-details/services/data-validation.service';
import { BudgetObjectAttachmentsService } from '../../../services/budget-object-attachments.service';
import { ExpensesService } from 'app/shared/services/backend/expenses.service';
import { createDateString, parseDateString } from '../campaign-details/date-operations';
import { HistoryObjectLogTypeNames } from 'app/shared/types/history-object-log-type.type';
import { getParentFromLocation } from 'app/shared/utils/location.utils';
import { HierarchySelectItem } from 'app/shared/components/hierarchy-select/hierarchy-select.types';
import { ExternalIntegrationExpenseSource } from 'app/shared/constants/external-integration-object-types';
import { MetricIntegrationName } from 'app/metric-integrations/types/metric-integration';
import { MetricIntegrationsProviderService } from 'app/metric-integrations/services/metric-integrations-provider.service';
import { MetricsProviderWithImplicitMappingsDataService } from 'app/metric-integrations/services/metrics-provider-with-implicit-mappings-data.service';
import { AbstractSelectOption } from 'app/shared/types/select-option.interface';
import { BudgetObjectOwnersService } from '../../../services/budget-object-owners.service';
import { ExpenseAllocationMode } from 'app/shared/types/expense-allocation-mode.type';
import { RelatedExpensesService } from '../../../services/related-expenses.service';
import { ChooseTimeframeDialogComponent, TimeframeDialogData } from '../../choose-timeframe-dialog/choose-timeframe-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ExpensePageDrawerService } from 'app/expenses/services/expense-page-drawer.service';
import { PdfViewerService } from '@shared/services/pdf-viewer.service';
import { InvoiceLiveTracking } from '@shared/services/invoice-live-tracking';
import { ProgramService } from '@shared/services/backend/program.service';
import { BudgetObject, BudgetObjectEventContext, BudgetObjectEventType } from '../../../types/budget-object-event.interface';
import { DetailsDrawerBaseComponent } from '../details-drawer-base';
import { DrawerFormFields } from '../details-drawer-form';
import { BudgetObjectActionsShared } from 'app/budget-object-details/services/budget-object-actions-shared';
import { ObjectDetailsTabsDataService } from 'app/budget-object-details/services/object-details-tab-data.service';
import { DrawerType } from '../../../services/drawer-stack.service';
import { Vendor } from '../../../../shared/services/company-data.service';
import { CFExpenseDetailsContext, CustomFieldsService, CustomFieldStateConfig } from '../../custom-fields/custom-field.service';
import { capitalizeString } from '@shared/utils/common.utils';

const SNACKBAR_DURATION = 10000;
const ON_VERIFY_SNACKBAR_DURATION = 7000;

interface ExpenseDetailsForm {
  name: string;
  segment: HierarchySelectItem;
  ownerId: number;
  typeId: number;
  glCode: number;
  poNumber: string;
  invoiceNumber: string;
  vendorId: number;
  vendorName: string;
  currencyCode: string;
  location: any;
  notes: string;
  deliveryDate: Date;

  sourceAmount: number; // planned
  sourceActualAmount: number; // actual
  status: string;
  budgetAllocationId: number;
  isVerified: boolean;
}

@Component({
  selector: 'expense-details',
  templateUrl: './expense-details.component.html',
  styleUrls: ['./expense-details.component.scss'],
  providers: [
    BudgetObjectTagsService,
    BudgetObjectAttachmentsService,
    RelatedExpensesService,
    ObjectDetailsTabsDataService,
    BudgetObjectActionsShared
  ]
})
export class ExpenseDetailsComponent extends DetailsDrawerBaseComponent<ExpenseDetailsState> implements BudgetObjectDetailsComponent {
  protected objectDetailsService = inject(ExpenseDetailsService);
  private readonly budgetObjectActionsShared = inject(BudgetObjectActionsShared);
  private readonly customFieldsService = inject(CustomFieldsService);

  @ViewChild('tagsControl') tagsControl: TagsControlComponent;
  @Input() vendors : Vendor[];

  public companyCurrencyCode: string;
  public expenseTypes: BudgetObjectType[] = [];

  public readonly campaignObjectType = this.configuration.OBJECT_TYPES.campaign;
  public readonly programObjectType = this.configuration.OBJECT_TYPES.program;
  public maxVendorNameLength = this.configuration.MAX_VENDOR_NAME_LENGTH;

  public hierarchy: ObjectHierarchy = {
    Goal: null,
    Program: null,
    Campaign: null,
    Expense: null
  };
  public isDataSaveInProgress = false;

  public currentSegmentId: number | null = null;
  public currentSharedCostRuleId: number | null = null;
  public expenseStatusList: AbstractSelectOption<string>[] =
    this.configuration.modes.map(mode => ({ id: mode, name: mode, iconCssClass: mode.toLowerCase()}));
  public contextExpenseStatusList: AbstractSelectOption<string>[] = [...this.expenseStatusList];
  private prevSourceAmount: number;
  private prevSourceActualAmount: number;
  private prevStatus: string;
  public convertedAmount: number;
  public convertedActualAmount: number;
  public difference: number;
  public convertedDifference: number;
  public currencyMaskOptions = { decimal: '.', precision: 2, align: 'right', allowNegative: true, prefix: '' };
  public expenseAllocationMode = ExpenseAllocationMode;
  public readonly utClasses = {
    difference: 'ut-difference',
  };
  public isExpenseUnverified = false;
  public StateField = StateField;
  private currentPrimaryRoutePath: string;
  private currentDetailsRouteData: DetailsRouteData;

  protected formConfig = {
    [DrawerFormFields.name]: ['', {
      validators: [Validators.required, Validators.maxLength(this.budgetObjectDetailsManager.maxObjectNameLength)],
    }],
    [DrawerFormFields.segment]: [null, this.dataValidation.segmentValidator()],
    [DrawerFormFields.ownerId]: [null, Validators.required],
    [DrawerFormFields.typeId]: [null, Validators.required],
    [DrawerFormFields.glCode]: null,
    [DrawerFormFields.poNumber]: ['', Validators.maxLength(500)],
    [DrawerFormFields.invoiceNumber]: ['', Validators.maxLength(500)],
    [DrawerFormFields.vendorId]: null,
    [DrawerFormFields.vendorName]: '',
    [DrawerFormFields.currencyCode]: ['', Validators.required],
    [DrawerFormFields.location]: null,
    [DrawerFormFields.notes]: '',
    [DrawerFormFields.deliveryDate]: null,
    [DrawerFormFields.sourceAmount]: [null, Validators.required],
    [DrawerFormFields.sourceActualAmount]: [null],
    [DrawerFormFields.status]: [null, Validators.required],
    [DrawerFormFields.budgetAllocationId]: [null, Validators.required],
    [DrawerFormFields.isVerified]: false
  };
  @Input() isInvoiceDrawer = false;
  
  isCustomFieldsFormValid: boolean;
  customFieldsStateDiffPayload: Record<number, string[]>;
  isCustomFieldsEnabledForExpense: boolean;
  resetCustomFieldsFormGroups: boolean;
  hasCustomFieldChanges: boolean = false;
  form: FormGroup;
  initialDropdownState: Record<string, any[]> = {};
  customFieldConfigs: CustomFieldStateConfig[] = [];
  
  public onDropdownStateChanged(selectedValue: any, index: number) {
    // Handle state change here
    const controlName = 'dropdown_' + index;
    this.form.get(controlName).setValue(selectedValue);

    let diffMap = this.customFieldsService.getStateDiff(this.initialDropdownState, this.form.getRawValue());
    
    let customFieldsPayload = this.customFieldsService.getSelectedOptionsIdPayloadForCF(diffMap, this.customFieldConfigs);  
    console.log("payload : ", customFieldsPayload);
    
    if(Object.keys(diffMap).length > 0){
      this.hasCustomFieldChanges = true;
    }else {
      this.hasCustomFieldChanges = false;
    }

    if( this.form.valid && Object.keys(diffMap).length > 0){
      this.syncUnsavedChangesFlag(true);
      this.customFieldsStateDiffPayload = customFieldsPayload;
    }else {
      this.syncUnsavedChangesFlag();
    }
    this.isCustomFieldsFormValid = this.form.valid
    console.log("diffMap: ", diffMap);
  }

  public initializeCustomFieldsForm() {  
    const controlsConfig = this.customFieldConfigs.reduce((acc, config, index) => {
      let defaultValue = config.defaultValue !== undefined ? config.defaultValue : null;
      let selectedValue: any = config.selectedValue !== undefined ? config.selectedValue : (this.objectId && !this.isInvoiceDrawer) ? undefined : defaultValue;
     
      if(config.isMultiSelect) {
        selectedValue = config.selectedValue.length ? config.selectedValue : (this.objectId && !this.isInvoiceDrawer) ? [] : defaultValue;
      }

      acc['dropdown_' + index] = [selectedValue, config.required ? Validators.required : null];
      return acc;
    }, {});
    this.form = this.formBuilder.group(controlsConfig);
    this.initialDropdownState = this.form.getRawValue();
    this.isCustomFieldsFormValid = this.form.valid;
    if(!this.objectId || this.isInvoiceDrawer){
      this.initialDropdownState = this.customFieldsService.initDefaultCustomFieldsFormData(this.isInvoiceDrawer ? null : this.objectId, this.form);
      let diffMap = this.customFieldsService.getStateDiff(this.initialDropdownState, this.form.getRawValue());
      let customFieldsPayload = this.customFieldsService.getSelectedOptionsIdPayloadForCF(diffMap, this.customFieldConfigs);
      this.customFieldsStateDiffPayload = customFieldsPayload
      console.log(diffMap);
    }
  }


  constructor(
    private readonly expenseDetailsService: ExpenseDetailsService,
    public readonly invoiceLiveTracking: InvoiceLiveTracking,
    public readonly relatedExpensesService: RelatedExpensesService,
    public readonly dataValidation: DataValidationService,
    private readonly menuActionsBuilder: BudgetObjectActionsBuilder,
    private readonly expensesService: ExpensesService,
    private readonly metricIntegrationsProvider: MetricIntegrationsProviderService,
    private readonly ownersService: BudgetObjectOwnersService,
    private readonly decimalPipe: DecimalPipe,
    private readonly matDialog: MatDialog,
    private readonly expensePageDrawerService: ExpensePageDrawerService,
    private readonly pdfViewerService: PdfViewerService,
    private readonly programService: ProgramService,
    private formBuilder: FormBuilder
  ) {
    super();
    this.setObjectType(this.configuration.OBJECT_TYPES.expense);
  }

  get relatedExpensesNumber(): number {
    return this.relatedExpensesService.relatedExpenses?.length || 0;
  }

  get isAddingRelatedExpensesDisabled(): boolean {
    return !this.relatedExpensesService.allowedAddingRelatedExpenses
      || this.isReadOnlyMode
      || this.isDataSaveInProgress
      || this.hasExternalIntegrationType;
  }

  protected onInit(): void {
    if(!this.cegStatusEnabled) {
      this.subscribeToVendorsAutocomplete();
    }    
    this.currentPrimaryRoutePath = this.appRoutingService.getCurrentActivatedPrimaryRoutePath();
    this.currentDetailsRouteData = this.appRoutingService.getCurrentActivatedDetailsRouteData();

    this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(event => this.closeDrawerOnPrimaryOrDetailsNavigation(event as ActivationEnd));

    this.expenseDetailsService.handleSaveAndCloseAction
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.submitChanges(this.handleSaveAndCloseAction.bind(this, this.isInvoiceDrawer)));

    this.expenseDetailsService.handleCancelAction
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.handleCancelAction());
  }

  protected onDestroy(): void {
    this.expensePageDrawerService.setOpenedExpenseId(null);
    this.expensePageDrawerService.setInitialFields(null, []);
  }

  public fetchCustomFieldsForExpense() {
    if(this.form) {
      this.form.reset();
    }

    this.showLoader();
    this.customFieldsService.fetchDropdownOptions(
      this.companyId, 
      this.objectId,
      capitalizeString(this.objectType)
    )
    .pipe(takeUntil(this.destroy$))
    .subscribe(data => { 
      console.log(data);
      this.customFieldConfigs = data;
      this.initializeCustomFieldsForm();
      this.hideLoader();
     });
  }

  public validateCustomFormFields() {
    let formGroup = this.form;
    if(!formGroup) return;

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } 
    });
  }

  private closeDrawerOnPrimaryOrDetailsNavigation(activationEndEvent: ActivationEnd): void {
    const { ROUTER_OUTLETS } = this.configuration;
    const { snapshot } = activationEndEvent;
    const trackedOutlets = [ROUTER_OUTLETS.PRIMARY, ROUTER_OUTLETS.DETAILS];

    if (!trackedOutlets.includes(snapshot.outlet)) {
      return;
    }

    if (snapshot.parent?.routeConfig) {
      // child route activation - not tracked currently
      return;
    }

    const routePath = this.appRoutingService.getRoutePath(snapshot);
    const params = snapshot.params;

    const primaryRouteChanged = snapshot.outlet === ROUTER_OUTLETS.PRIMARY && routePath !== this.currentPrimaryRoutePath;
    const detailsRouteChanged = snapshot.outlet === ROUTER_OUTLETS.DETAILS &&
      (routePath !== this.currentDetailsRouteData?.routePath || params?.id !== this.currentDetailsRouteData?.idParam);

    if (primaryRouteChanged || detailsRouteChanged) {
      this.handleCancelAction();
    }
  }

  protected onBudgetChanged(budget: Budget): void {
    this.updateAmountValidator();
  }

  protected checkTagsLeftover(): void {
    if (!this.tagsControl) {
      return;
    }
    this.tagsManager.checkInputLeftover(this.tagsControl, this.currentState.tagMappings);
  }

  private updateAmountValidator(): void {
    this.manageControlValidators(this.fdSourceActualAmountControl, [Validators.required], this.cegStatusEnabled);
  }

  protected initObjectCreation(): void {
    this.showLoader();
    this.relatedExpensesService.reset();
    this.creationContext = AppRoutingService.getHistoryStateProperty<BudgetObjectCreationContext>('data');

    this.loadBudgetAndCompanyData$().pipe(
      mergeMap(([company, budget]) => (
        combineLatest([
          this.expenseDetailsService.initDetails(this.creationContext, { company, budget }),
          this.loadDetailsContextData(company)
        ])
      )),
      map(([objectDetailsState]) => {
        this.setCreatedBy(objectDetailsState as ObjectDetailsCommonState);
        this.detectAddedParentObject(objectDetailsState as ObjectDetailsCommonState);
        if(!this.cegStatusEnabled) {
          this.defineVendorName(objectDetailsState as ObjectDetailsCommonState);
        } 
        objectDetailsState.currencyCode = this.companyCurrencyCode;
        this.expenseTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.expenseTypes);
        this.validateContextObjectType(this.expenseTypes, objectDetailsState as ObjectDetailsCommonState);
        this.isExpenseUnverified = false;

        if (this.cegStatusEnabled) {
          objectDetailsState.mode = ExpenseAllocationMode.Closed;
           this.customFieldsService.getCFStatus().subscribe(status => {
            this.isCustomFieldsEnabledForExpense = status?.isCFEnabledForExpense;
            if(this.isCustomFieldsEnabledForExpense) {
              this.fetchCustomFieldsForExpense();
            }
          })
        }
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => this.onExpenseLoaded(state, false),
      error: error => this.onError(
        error,
        messages.UNABLE_TO_CREATE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase()),
        true
      )
    });
  }

  protected initHierarchy(objectDetailsState): void {
    if (!objectDetailsState.objectId) {
      return;
    }
    const segmentId = objectDetailsState.segment.segmentId || null;
    const segment = this.segments.find(seg => seg.id === segmentId);

    this.hierarchy = this.hierarchyService.buildObjectHierarchy(
      {
        id: objectDetailsState.objectId,
        name: objectDetailsState.name,
        sharedCostRuleId: objectDetailsState.segment.sharedCostRuleId || null,
        segmentId,
        segmentGroupId: segment?.segment_group || null,
        campaignId: objectDetailsState.campaignId,
        programId: objectDetailsState.programId,
        mode: !this.cegStatusEnabled && objectDetailsState.mode,
      },
      this.objectType,
      {
        segmentGroups: this.segmentGroups,
        segments: this.segments,
        sharedCostRules: this.sharedCostRules,
        campaigns: this.campaigns,
        programs: this.programs
      }
    );
  }

  protected loadObjectDetails(expenseId: number): void {
    this.showLoader();
    this.relatedExpensesService.reset();
    this.loadBudgetAndCompanyData$().pipe(
      switchMap(([companyId, budgetId]) =>
        combineLatest([
          this.expenseDetailsService.loadDetails(companyId, budgetId, expenseId, { generateExtraUrls: this.isInvoiceDrawer }),
          this.loadDetailsContextData(companyId)
        ])
      ),
      tap(([state]) => this.checkStateConsistencyAndAccess(state as ObjectDetailsCommonState)),
      map(([objectDetailsState]) => {
        this.initHierarchy(objectDetailsState);
        this.defineParent(objectDetailsState);
        if(!this.cegStatusEnabled) {
          this.defineVendorName(objectDetailsState as ObjectDetailsCommonState);
        }        
        this.expenseTypes = this.budgetObjectDetailsManager.filterObjectTypes(this.expenseTypes, objectDetailsState.typeId);
        this.loadAttachments(objectDetailsState as ObjectDetailsCommonState);
        if(this.cegStatusEnabled){
        this.customFieldsService.getCFStatus().subscribe(status => {
          this.isCustomFieldsEnabledForExpense = status?.isCFEnabledForExpense;
          if(this.isCustomFieldsEnabledForExpense) {
            this.fetchCustomFieldsForExpense();
          }
        })
      }
        return objectDetailsState;
      }),
      takeUntil(merge(this.destroy$, this.reset$))
    ).subscribe({
      next: state => {
        this.onExpenseLoaded(state);
        this.setRelationExpensesData(state);
        this.setContextExpenseStatusList();
        this.budgetObjectDetailsManager.logObjectView(
          state.objectId,
          this.companyId,
          this.budgetId,
          this.currentCompanyUser.user,
          HistoryObjectLogTypeNames.expense,
          this.expenseDetailsService);
        this.isExpenseUnverified = state.isVerified != null && !state.isVerified;
      },
      error: error => this.onError(error, messages.NO_OBJECT_FOUND_ERROR_MSG.replace(objectPlaceholderName, this.objectTypeAsText), true)
    });
  }

  onExpenseLoaded(state, syncState: boolean = true): void {
    this.currentState = state;
    if (syncState) {
      this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(state);
      const recognizedFields = this.isInvoiceDrawer ? Object.keys(state.recognizedFields) : [];
      const { segmentId, sharedCostRuleId } = this.prevState.segment;
      this.setCurrentSegmentId(segmentId, sharedCostRuleId);
      this.expensePageDrawerService.setInitialFields(this.prevState, recognizedFields);
      this.pdfViewerService.setSource(state.pdfUrl, false);
      this.expensePageDrawerService.setExpenseEmailBody(state.bodyEmailContents);
    }
    this.applyExternalIntegrationRestrictions();
    this.updateMenuActions();
    this.updateOwnerOptions(this.currentState.segment.segmentId, this.currentState.segment.sharedCostRuleId);
    this.defineAllowedSegments(this.currentState.ownerId);
    this.loadCurrencyExchangeRates(this.currentState.currencyCode);
    this.setFormData();
    this.updateLocationOptions();
    if (this.cegStatusEnabled) {
      this.autofillSegmentFromParent(this.fdLocation, true);
    }
    this.hideLoader();

    this.formData.valueChanges
      .pipe(
        debounceTime(300),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.syncUnsavedChangesFlag());
  }

  protected updateLocationOptions(): void {
    const currentLocation = this.fdLocation;
    const locationParent = getParentFromLocation(currentLocation);
    const locationGoals = locationParent && locationParent.type === this.configuration.OBJECT_TYPES.goal ?
      this.goals.filter(goal => goal.id === locationParent.id) :
      null;

    this.setLocationOptions(
      {
        goals: locationGoals,
        campaigns: this.campaigns,
        programs: this.programs,
        currentLocation,
        segments: this.segments,
        rules: this.sharedCostRules,
        isPowerUser: this.isPowerUser
      }, currentLocation, false, true
    );
  }

  protected defineParent(objectDetailsState: ExpenseDetailsState): void {
    objectDetailsState.parentObject = this.hierarchyService.getParentFromHierarchy(this.hierarchy, this.objectType);
  }

  loadDetailsContextData(companyId: number) {
    return combineLatest(
      [
        this.budgetObjectDetailsManager.getCompanyCurrency(
          companyId,
          error => this.onError(error, messages.UNABLE_TO_LOAD_ACCOUNT_CURRENCY_ERROR_MSG)
        ).pipe(tap(currency => this.companyCurrencyCode = currency)),
        this.getVendors$,
        this.budgetObjectDetailsManager.getCompanyUsers().pipe(tap(users => this.companyUsers = users)),
        this.budgetObjectDetailsManager.getTags().pipe(tap(tags => this.tagsManager.setTags(tags))),
        this.budgetObjectDetailsManager.getExpenseTypes().pipe(tap(types => this.expenseTypes = types)),
        this.getGLCodes$,
        this.budgetObjectDetailsManager.getBudgets(this.budgetId).pipe(tap(budgets => this.budgets = budgets)),
        this.budgetObjectDetailsManager.getTimeframes().pipe(tap(tfs => this.budgetTimeframes = tfs)),
        this.getSegmentRelatedData$,
        this.budgetObjectDetailsManager.getGoals().pipe(tap(goals => this.goals = goals)),
        this.budgetObjectDetailsManager.getLightCampaigns().pipe(tap(campaigns => this.campaigns = campaigns)),
        this.budgetObjectDetailsManager.getLightPrograms().pipe(tap(programs => this.programs = programs)),
        this.budgetObjectDetailsManager.getCompanyCurrencies(companyId).pipe(tap(currencyList => this.currencyList = currencyList)),
        this.currentCompanyUser$
      ]
    );
  }

  public saveChanges(onSavedCb: Function = null, runInBackground = false): void {
    if (!runInBackground) {
      this.showLoader();
    }

    const isNewExpense = !this.currentState.objectId;
    let CFDetailsCtx: CFExpenseDetailsContext = { 
      isCFEnabledForExpense: this.isCustomFieldsEnabledForExpense,
      customFieldsStateDiff: this.customFieldsStateDiffPayload
    }

    forkJoin([
      this.tagsManager.createTags(this.companyId, this.tagsManager.getLocalTags()),
      this.createVendor$()
    ]).pipe(
      tap(([addedTags, addedVendor]) => {
        if(!this.cegStatusEnabled) {
          this.applyCreatedVendor(addedVendor);
        }
        this.saveFormData();
        this.applyCreatedTags(addedTags);
      }),
      switchMap(() => this.expenseDetailsService.saveDetails(this.prevState, this.currentState,null ,CFDetailsCtx)),
      switchMap((data: ExpenseDO) => this.saveMappings(data)),
      mergeMap((expense: ExpenseDO) =>
        this.budgetObjectDetailsManager.processDynamicTagMappings(
          this.companyId,
          expense.id,
          this.currentState.tagMappings,
          this.configuration.OBJECT_TYPES.expense,
          this.prevState && this.prevState.parentObject,
          this.currentState.parentObject
        ).pipe(
          tap(result => {
            this.tagsManager.applyDeletedMappings(this.currentState.tagMappings, result?.detached);
            this.tagsManager.applyAttachedDynamicMappings(this.currentState.tagMappings, result?.attached);
          })
        )
      )
    ).subscribe({
      next: () => {
        this.onSavedSuccessfully(runInBackground, isNewExpense);
        onSavedCb?.();
      },
      error: error => this.onError(
        error,
        messages.UNABLE_TO_SAVE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
      )
    });
  }

  saveMappings(expense) {
    const tagInc = this.tagsManager.getTagsIncrement(
      this.prevState && this.prevState.tagMappings,
      this.currentState && this.currentState.tagMappings
    );

    const objectId = this.currentState.objectId;

    return forkJoin([
      this.tagsManager.deleteTagMappings(tagInc.deleted),
      this.tagsManager.createTagMappings(objectId, this.objectType, tagInc.created),
    ]).pipe(
      tap(([, createdTags]) => {
        this.tagsManager.applyCreatedMappings(this.currentState.tagMappings, createdTags);
      }),
      map(() => expense)
    );
  }

  protected submitChanges(submitCallback: () => void, notValidCallback?: () => void): void {
    this.submitForm(submitCallback, notValidCallback);
  }

  onSavedSuccessfully(runInBackground: boolean, isNewExpense: boolean): void {
    if (!this.prevState) {
      this.attachmentsManager.setObjectContext({
        objectId: this.currentState.objectId,
        objectType: this.objectType,
        companyId: this.companyId
      });
      this.budgetObjectDetailsManager.logObjectView(
        this.currentState.objectId,
        this.companyId,
        this.budgetId,
        this.currentCompanyUser.user,
        HistoryObjectLogTypeNames.expense,
        this.expenseDetailsService
      );
      this.budgetObjectDetailsManager.refreshRecentlyAddedObjects(
        this.budgetId,
        HistoryObjectLogTypeNames.expense,
        this.segments
      );
      this.updateMenuActions();
      this.setRelationExpensesData(this.currentState);
    }
    this.checkParentChange();

    this.initHierarchy(this.currentState);
    const prevState = this.prevState;
    this.prevState = this.budgetObjectDetailsManager.getDeepStateCopy(this.currentState);
    this.isInvoiceDrawer ?
      this.onVerifySuccess(messages.VERIFY_INVOICE_SUCCESS_MSG, this.currentState.objectId) :
      this.onSuccess(messages.SAVE_CHANGES_SUCCESS_MSG, false, runInBackground);
    this.budgetObjectDetailsManager.reportDetailsChange(this);
    this.triggerBudgetObjectEvent(
      this.currentState.objectId,
      isNewExpense ? BudgetObjectEventType.Created : BudgetObjectEventType.Updated,
      prevState
    );
    this.objectId = this.currentState.objectId;
    this.syncUnsavedChangesFlag(false);
    this.hierarchyService.setHierarchyObjectName(
      this.hierarchy,
      this.configuration.OBJECT_TYPES.expense,
      this.currentState.name
    );

    if(this.isCustomFieldsEnabledForExpense){
      this.resetCustomFieldsFormGroups = true;
      this.hasCustomFieldChanges = false;
      this.initialDropdownState = this.form?.getRawValue();
    }

  }

  private triggerBudgetObjectEvent(expenseId: number, eventType: BudgetObjectEventType, prevState?: ExpenseDetailsState): void {
    if (!this.isInvoiceDrawer) {
      const context: BudgetObjectEventContext = {
        objectId: expenseId,
        segmentId: this.currentState.segment?.segmentId,
        sharedCostRuleId: this.currentState.segment?.sharedCostRuleId,
        parentObject: this.currentState.parentObject
      };

      if (prevState) {
        context.prevSegmentId = prevState.segment?.segmentId;
        context.prevSharedCostRuleId = prevState.segment?.sharedCostRuleId;
        context.prevParentObject = prevState.parentObject;
      }

      this.budgetObjectDetailsManager.triggerBudgetObjectEvent({
        targetObject: BudgetObject.Expense,
        eventType,
        context
      });
    }
  }

  /* TAGS */
  createTag(tag: TagControlEvent): void {
    this.tagsManager.addLocalTag(tag);
    this.tagsManager.addLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  addTag(tag: TagControlEvent): void {
    this.tagsManager.addLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  removeTag(tag: TagControlEvent): void {
    this.tagsManager.deleteLocalTag(tag);
    this.tagsManager.deleteLocalMapping(tag, this.currentState.tagMappings);
    this.syncUnsavedChangesFlag();
  }

  getConvertedAmount(amount: number, currencyCode: string, expenseAllocId: number): number {
    return currencyCode === this.companyCurrencyCode ?
      amount :
      this.budgetObjectDetailsManager.getConvertedAmount(amount, currencyCode, expenseAllocId);
  }

  protected onCurrencyExchangeRatesUpdated(): void {
    setTimeout(() => {
      this.updateConvertedAmounts();
    })
  }

  protected updateConvertedAmounts(): void {
    const currencyCode = <string>this.formData.get('currencyCode')?.value;
    const budgetAllocationId = <number>this.formData.get('budgetAllocationId')?.value;
    const sourceAmount = <number>this.formData.get('sourceAmount')?.value;
    const sourceActualAmount = this.fdSourceActualAmount;
    this.convertedAmount = this.getConvertedAmount(sourceAmount, currencyCode, budgetAllocationId);
    this.convertedActualAmount = this.getConvertedAmount(sourceActualAmount, currencyCode, budgetAllocationId);
    this.convertedDifference = (this.convertedAmount || 0) - (this.convertedActualAmount || 0);
  }

  handleStatusUpdate(statusValue: string): void {
    const splitStatusValue = statusValue.split('_');
    const plannedValue = <number>this.formData.get('sourceAmount')?.value;
    const sourceAmountControl = this.fdSourceActualAmountControl;
    const shouldResetActual =
      this.prevStatus !== ExpenseAllocationMode.Planned && splitStatusValue[0] === ExpenseAllocationMode.Planned;

    if (splitStatusValue.length > 1 || shouldResetActual) {
      sourceAmountControl.setValue(shouldResetActual ? 0 : plannedValue);
      setTimeout(
        () => this.fdStatusControl.setValue(splitStatusValue[0])
      );
      this.updateConvertedAmounts();
    }
    this.updateDifference(splitStatusValue[0], plannedValue, sourceAmountControl?.value);
    this.setContextExpenseStatusList(splitStatusValue[0]);
    this.prevStatus = splitStatusValue[0];
  }

  handleSourceAmountChange(): void {
    const newValue = <number>this.formData.get('sourceAmount')?.value;
    const statusControl = this.fdStatusControl;
    const sourceActualAmount = this.fdSourceActualAmount;
    if (!statusControl.value && !this.prevSourceAmount && !sourceActualAmount && newValue && !this.cegStatusEnabled) {
      statusControl.setValue(ExpenseAllocationMode.Planned);
    }
    this.updateDifference(statusControl.value, newValue, sourceActualAmount);
    this.setContextExpenseStatusList();
    this.updateConvertedAmounts();
    this.prevSourceAmount = newValue;
  }

  updateDifference(status: string, plannedAmount: number, actualAmount: number): void {
    this.difference =
      status === ExpenseAllocationMode.Planned ?
        null :
        (plannedAmount || 0) - (actualAmount || 0);
  }

  handleSourceActualAmountChange(): void {
    const newValue = this.fdSourceActualAmount;
    const sourceAmount = <number>this.formData.get('sourceAmount')?.value;
    const statusControl = this.fdStatusControl;
    if (!this.prevSourceActualAmount && newValue && !this.cegStatusEnabled) {
      statusControl.setValue(ExpenseAllocationMode.Committed);
    }
    this.difference = (sourceAmount || 0) - (newValue || 0);
    this.updateDifference(statusControl.value, sourceAmount, newValue);
    this.setContextExpenseStatusList();
    this.updateConvertedAmounts();
    this.prevSourceActualAmount = newValue;
  }

  setContextExpenseStatusList(useCurrentStatus: string = null): void {
    const currentStatus = useCurrentStatus || this.fdStatus;
    const budgetAllocationId = <number>this.formData.get('budgetAllocationId')?.value;
    const sourceAmount = <number>this.formData.get('sourceAmount')?.value;
    const isLocked = this.budgetTimeframes.find(tf => tf.id === budgetAllocationId)?.locked;

    if (currentStatus === ExpenseAllocationMode.Closed || this.fdSourceActualAmount || !sourceAmount || isLocked) {
      this.contextExpenseStatusList = [...this.expenseStatusList];
    } else {
      this.contextExpenseStatusList =
        this.expenseStatusList.flatMap(
          status => {
            if (status.id === ExpenseAllocationMode.Closed ||
              (status.id === ExpenseAllocationMode.Committed && currentStatus === ExpenseAllocationMode.Planned)
            ) {
              const name = status.id === ExpenseAllocationMode.Closed ? ExpenseAllocationMode.Closed : ExpenseAllocationMode.Committed;
              const withPlannedAmount = {
                iconCssClass: name.toLowerCase(),
                name: `${name} at ${this.decimalPipe.transform(sourceAmount, '1.2-2')}`,
                id: status.id + '_at_planned',
              };
              const withNullValue = {
                iconCssClass: name.toLowerCase(),
                name: `${name} at ${this.decimalPipe.transform(0, '1.2-2')}`,
                id: status.id,
              };
              return [withPlannedAmount, withNullValue];
            } else {
              return [status];
            }
          }
        );
    }
  }

  get segmentControl(): AbstractControl {
    return this.formData.get('segment');
  }

  handleSegmentChanged(event: HierarchySelectItem): void {
    const { segmentId, sharedCostRuleId } = this.budgetObjectDetailsManager.hierarchyItemToState(event);
    this.setCurrentSegmentId(segmentId, sharedCostRuleId);
    this.updateOwnerOptions(segmentId, sharedCostRuleId);
  }

  protected autofillSegmentFromParent(parentValue: string, isEditMode = false): void {
    const locationParent = getParentFromLocation(this.fdLocation);
    const segmentControlValueId = this.segmentControl?.value?.objectId;

    if (locationParent) {
      const parentObject = this.configuration.OBJECT_TYPES.campaign === locationParent.type ?
      this.campaigns.find((campaign)=> campaign.id === locationParent.id) :
      this.programs.find((program)=> program.id === locationParent.id);
      const { budgetSegmentId: segmentId, splitRuleId: sharedCostRuleId } = parentObject;
      const segmentedValue = this.budgetObjectDetailsManager.segmentedValueToSelectItem({segmentId, sharedCostRuleId}, this.segmentSelectItems);
      const snackbarMessage = this.getSnackbarMessageForSegmentAutofill(segmentControlValueId,  segmentedValue.objectId, isEditMode);
      const hasUndo = segmentControlValueId !== segmentedValue.objectId;
      const rollbackAction = this.rollbackSegmentAutofill.bind(this, this.currentSegmentId, this.currentSharedCostRuleId, this.segmentControl.value, parentValue);

      this.handleSnackbarMessage(snackbarMessage, hasUndo, isEditMode, rollbackAction);
      this.setCurrentSegmentId(segmentId, sharedCostRuleId);
      this.updateOwnerOptions(segmentId, sharedCostRuleId);
      this.segmentControl.setValue(segmentedValue);
    }
  }

  private rollbackSegmentAutofill(segmentId: number | null, sharedCostRuleId: number | null, segmentedValue: HierarchySelectItem, parentValue): void {
      this.setCurrentSegmentId(segmentId, sharedCostRuleId);
      this.updateOwnerOptions(segmentId, sharedCostRuleId);
      this.segmentControl.setValue(segmentedValue);
      this.handleParentSelectionChange(parentValue, false);
  }

  private getSnackbarMessageForSegmentAutofill(parentSegmentId: number, segmentId: number, isEditMode = false): string {
    let message: string;

    if (isEditMode) {
      message = `The expense's segment was automatically updated to match its parent.`;
    } else if (parentSegmentId === segmentId) {
      message = 'Parent updated.';
    } else {
      message = 'Updated segment from the parent.';
    }

    return message;
  }

 private handleSnackbarMessage(message: string, hasUndo: boolean, isEditMode: boolean, callback?: ()=> void): void {
    if (hasUndo) {
      this.utilityService.showCustomToastr(message, 'UNDO', { timeOut: SNACKBAR_DURATION/2 })
        .onAction
        .pipe(take(1))
        .subscribe(() => callback());
    } else if (!isEditMode) {
      this.utilityService.showCustomToastr(message, null, { timeOut: SNACKBAR_DURATION/2 });
    }
  }

  /* ACTIONS */
  protected updateMenuActions(): void {
    if (!(this.currentState && this.currentState.objectId)) {
      this.menuActions = [];
      return;
    }
    const isBudgetActionDisabled = this.hasExternalIntegrationType || this.isReadOnlyMode;

    this.menuActionsBuilder.reset();

    if (this.cegStatusEnabled) {
      this.menuActionsBuilder
        .addShowParentAction(this.objectLabel)
        .addCloneAction(this.objectLabel, this.handleClone.bind(this), this.isReadOnlyMode)
        .addChangeBudgetAction(isBudgetActionDisabled)
        .addDeleteAction(this.objectLabel, this.handleDelete.bind(this), this.isReadOnlyMode);
    } else {
      this.menuActionsBuilder
        .addCloneAction(this.objectLabel, this.handleClone.bind(this), this.isReadOnlyMode)
        .addChangeBudgetAction(isBudgetActionDisabled)
        .addDeleteAction(this.objectLabel, this.handleDelete.bind(this), this.isReadOnlyMode);
    }

    this.menuActions = this.menuActionsBuilder.getActions();
  }

  protected handleClone(): void {
    this.onObjectClone(expenseId => {
      this.triggerBudgetObjectEvent(expenseId, BudgetObjectEventType.Created);
      this.appRoutingService.replaceActiveDrawer(DrawerType.Expense, expenseId);
    });
  }

  handleDelete(): void {
    const externalIntegrationSources = Object.values(ExternalIntegrationExpenseSource);
    let lockTimeframeSync$: Observable<void> = of(null);
    const integrationType = this.currentState.source;
    const isIntegrated = externalIntegrationSources.includes(integrationType);

    if (isIntegrated) {
      const tfOrder = ExpensesService.getTimeframeOrder(this.currentState.budgetAllocationId, this.budgetTimeframes);

      const provider: MetricsProviderWithImplicitMappingsDataService =
        this.metricIntegrationsProvider.metricIntegrationProviderByType(integrationType as MetricIntegrationName);

      if (tfOrder != null && provider) {
        lockTimeframeSync$ = provider.lockTimeframesForIntegration(
          [tfOrder], this.companyId, this.currentState.campaignId
        );
      }
    }

    const customContent = !isIntegrated ? null :
      {
        title: this.expensesService.messages.CONFIRM_AUTOMATED_EXPENSE_DELETION_TITLE_SINGULAR,
        message: this.expensesService.messages.CONFIRM_AUTOMATED_EXPENSE_DELETION_SINGULAR,
      };

    this.dialogManager.openDeleteEntityDialog(() => {
      this.expenseDetailsService.deleteObject(this.currentState.objectId)
        .subscribe({
          next: () => {
            this.isDeleteActionObject.value = true;
            this.onSuccess(messages.DELETE_OBJECT_SUCCESS_MSG.replace(objectPlaceholderName, this.objectType), true);
            this.budgetObjectDetailsManager.reportDetailsChange(this);
            this.triggerBudgetObjectEvent(this.currentState.objectId, BudgetObjectEventType.Deleted);
            lockTimeframeSync$.subscribe();
          },
          error: error => this.onError(
            error,
            messages.UNABLE_TO_DELETE_OBJECT_ERROR_MSG.replace(objectPlaceholderName, this.objectType.toLowerCase())
          )
        });
    }, this.objectType.toLowerCase(), customContent);
  }

  handleBudgetToMoveSelected(budget: Budget): void {
    this.budgetObjectActionsShared.handleBudgetToMoveSelected(
      budget,
      this.companyId,
      this.currentState.objectId,
      this.objectType,
      this.cegStatusEnabled
    );
  }

  handleSaveAction(): void {
    this.checkTagsLeftover();

    if (this.unsavedChangesFlag || this.hasUnsavedChanges()) {
      this.saveChanges();
    }
  }

  protected handleCancelAction(): void {
    this.appRoutingService.closeActiveDrawer(this.isInvoiceDrawer);
  }

  applyCreatedTags(addedTags: Tag[]): void {
    if (addedTags && addedTags.length) {
      this.companyDataService.loadTags(this.companyId);
      this.tagsManager.applyAddedTags(addedTags, this.currentState.tagMappings);
    }
  }

  protected formDataToState(): Partial<ExpenseDetailsState> {
    const formData = this.formData.getRawValue() as ExpenseDetailsForm;
    const stateSegment = this.budgetObjectDetailsManager.hierarchyItemToState(formData.segment);
    const deliveryDate = createDateString(formData.deliveryDate);
    const state: Partial<ExpenseDetailsState> = {
      notes: formData[DrawerFormFields.notes],
      glCode: formData[DrawerFormFields.glCode],
      poNumber: formData[DrawerFormFields.poNumber],
      invoiceNumber: formData[DrawerFormFields.invoiceNumber],
      name: formData[DrawerFormFields.name],
      currencyCode: formData[DrawerFormFields.currencyCode],
      vendor: formData[DrawerFormFields.vendorId],
      vendorName: formData[DrawerFormFields.vendorName],
      typeId: formData[DrawerFormFields.typeId],
      ownerId: formData[DrawerFormFields.ownerId],
      segment: stateSegment,
      deliveryDate,
      sourceAmount: formData[DrawerFormFields.sourceAmount],
      sourceActualAmount: formData[DrawerFormFields.sourceActualAmount],
      mode: formData[DrawerFormFields.status],
      budgetAllocationId: formData[DrawerFormFields.budgetAllocationId],
      isVerified: formData[DrawerFormFields.isVerified]
    };

    state.parentObject = getParentFromLocation(formData.location);
    if (state.parentObject) {
      state[state.parentObject.type.toLowerCase() + 'Id'] = state.parentObject.id;
    } else {
      state.campaignId = null;
      state.programId = null;
    }
    return state;
  }

  private setFormData(): void {
    const {
      segment,
      ownerId,
      typeId = null,
      glCode,
      poNumber,
      invoiceNumber,
      vendor,
      vendorName = '',
      name,
      notes = '',
      currencyCode = null,
      deliveryDate,
      sourceAmount,
      sourceActualAmount,
      mode = null,
      budgetAllocationId = null,
      amount,
      actualAmount,
      isVerified
    } = this.currentState;
    const location = this.locationService.defineLocationValue(this.currentState.parentObject);
    const segmentedValue = this.budgetObjectDetailsManager.segmentedValueToSelectItem(segment, this.segmentSelectItems);
    const owner = ownerId || (this.currentCompanyUser?.user);
    const deliveryDateObj = parseDateString(deliveryDate);
    this.formData.setValue({
      segment: segmentedValue,
      ownerId: owner,
      typeId,
      glCode,
      poNumber,
      invoiceNumber,
      vendorId: vendor,
      vendorName,
      currencyCode: currencyCode || this.companyCurrencyCode,
      location,
      notes,
      name,
      deliveryDate: deliveryDateObj,
      sourceAmount,
      sourceActualAmount,
      status: mode,
      budgetAllocationId,
      isVerified: isVerified || false
    });

    this.prevSourceAmount = sourceAmount;
    this.prevSourceActualAmount = sourceActualAmount;
    this.prevStatus = mode;
    this.convertedAmount = amount;
    this.convertedActualAmount = actualAmount;
    this.convertedDifference = (this.convertedAmount || 0) - (this.convertedActualAmount || 0);
    this.updateDifference(mode, sourceAmount, sourceActualAmount);

    this.performAutofill();
  }

  private performAutofill(): void {
    this.budgetObjectDetailsManager.autofillSegmentValue(
      this.segmentControl,
      this.segmentSelectItems
    );
    this.budgetObjectDetailsManager.autofillTypeSelectValue(
      this.formData.get('typeId'),
      this.expenseTypes.filter(type => !!type.id)
    );
  }

  private onVerifySuccess (message: string, expenseId: number): void {
    this.updateInvoiceCount();
    this.utilityService.showCustomToastr(
      `${message}`,
      'View',
      { timeOut: ON_VERIFY_SNACKBAR_DURATION }
    )
      .onAction
      .pipe(take(1))
      .subscribe(() => this.appRoutingService.openExpenseDetails(expenseId));
  }

  protected getContextForNewObjectCreation(): BudgetObjectCreationContext {
    return BudgetObjectActionsShared.getContextForNewObjectCreation(this.currentState);
  }

  public getContextForChildObjectCreation() {
    return null;
  }

  public handleParentSelectionChange(value: string, autofillEnabled = true): void {
    const previousParentValue = this.fdLocation;
    this.formData.get('location').setValue(value);
    if (autofillEnabled && this.cegStatusEnabled) {
      this.autofillSegmentFromParent(previousParentValue);
    }
    if (value) {
      const [ objType, objId ] = value.split('_');
      if (objType === this.configuration.OBJECT_TYPES.program) {
        this.inheritExpenseGroupFields(Number(objId));
      }
    }
  }

  private applyExternalIntegrationRestrictions(): void {
    const fieldsToDisable = [DrawerFormFields.name, DrawerFormFields.typeId, DrawerFormFields.currencyCode, DrawerFormFields.location];
    const { filteredObjectTypes, integrationTypeSelected } = this.applyExternalIntegrationToForm(
      fieldsToDisable,
      this.expenseTypes,
      this.currentState.typeId
    );
    this.hasExternalIntegrationType = integrationTypeSelected;
  }

  get glCodeActiveName(): string {
    const triggerItem = this.glCodes.find(code => code.id === this.formData.controls['glCode'].value);
    return triggerItem ? triggerItem.name : null;
  }

  private setCurrentSegmentId(updatedSegmentId: number | null, updatedSharedCostRulesId: number | null): void {
    this.currentSegmentId = updatedSegmentId;
    this.currentSharedCostRuleId = updatedSharedCostRulesId;
  }

  private updateInvoiceCount(): void {
    this.invoiceLiveTracking.restartTracking();
  }

  private manageControlValidators(control: AbstractControl, validators: ValidatorFn[], shouldAdd: boolean) {
    shouldAdd ? control.setValidators(validators) : control.clearValidators();
    control.updateValueAndValidity();
  }

  get currentTimeframe(): BudgetTimeframe | null {
    return this.budgetTimeframes.find((frame) => frame.id === this.fdBudgetAllocationId) || null;
  }

  inheritExpenseGroupFields(programId: number): void {
    this.programService.getProgram(programId).pipe(
      catchError(() => of(null)),
      filter((program: ProgramDO) => program?.gl_code != null || program.po_number != null)
    ).subscribe(
      program => {
        const { gl_code: updGlCode, po_number: updPONumber } = program;
        let rollbackPayload = {};
        let snackbarMessage = '';
        const { glCode, poNumber } = this.formData.value;
        const inheritBoth = updGlCode && updPONumber;
        let patchValueObject = {};

        if (inheritBoth) {
          rollbackPayload = { [DrawerFormFields.glCode]: glCode, [DrawerFormFields.poNumber]: poNumber };
          snackbarMessage = 'GL Code and PO # updated from expense group';
          patchValueObject = { [DrawerFormFields.glCode]: updGlCode, [DrawerFormFields.poNumber]: updPONumber };
        } else if (updGlCode) {
          rollbackPayload = { [DrawerFormFields.glCode]: glCode };
          snackbarMessage = 'GL Code updated from expense group';
          patchValueObject = { [DrawerFormFields.glCode]: updGlCode };
        } else if (updPONumber) {
          rollbackPayload = { [DrawerFormFields.poNumber]: poNumber };
          snackbarMessage = 'PO # updated from expense group';
          patchValueObject = { [DrawerFormFields.poNumber]: updPONumber };
        }

        if (snackbarMessage) {
          this.formData.patchValue(patchValueObject);
          this.utilityService.showCustomToastr(snackbarMessage, 'UNDO', { timeOut: SNACKBAR_DURATION })
            .onAction
            .subscribe(() =>
              this.formData.patchValue(rollbackPayload)
            );
        }
      }
    );
  }

  setRelationExpensesData(state: ExpenseDetailsState) {
    this.relatedExpensesService.initForExpense({
      expenseId: state.objectId,
      relationGroupId: state.relationGroupId,
      companyId: this.companyId,
      budgetId: this.budgetId,
      budgetTimeframes: this.budgetTimeframes
    });
  }

  openRelatedExpensesTimeframesDialog(): MatDialogRef<ChooseTimeframeDialogComponent> {
    const selectedTimeframeIds = this.relatedExpensesService.relatedExpenses.map(exp => exp.company_budget_alloc);
    const currentExpenseAllocationId = this.formData.controls['budgetAllocationId'].value;
    if (!selectedTimeframeIds.includes(currentExpenseAllocationId)) {
      selectedTimeframeIds.push(currentExpenseAllocationId);
    }
    const dialogData: TimeframeDialogData = {
      showLegend: this.budgetTimeframes.length === 12,
      timeframes: this.budgetTimeframes.map(tf => ({
        id: tf.id,
        title: tf.name.substring(0, 3),
        locked: tf.locked,
        selected: selectedTimeframeIds.includes(tf.id),
      })),
    };

    return this.matDialog.open(ChooseTimeframeDialogComponent, {
      width: '410px',
      autoFocus: false,
      data: dialogData
    });
  }

  onAddRelatedExpensesClick(): void {
    if (this.unsavedChangesFlag || !this.currentState.objectId) {
      const validFormCb = () => {
        this.isDataSaveInProgress = true;
        this.saveChanges(() => {
          this.isDataSaveInProgress = false;
          this.addRelatedExpenses();
        }, true);
      };
      const notValidFormCb = () => {
        this.utilityService.showCustomToastr('Fix validation errors, and try again!');
      };
      this.submitChanges(validFormCb, notValidFormCb);
    } else {
      this.addRelatedExpenses();
    }
  }

  addRelatedExpenses(): void {
    if (this.relatedExpensesService.allowedAddingRelatedExpenses && !this.isReadOnlyMode) {
      this.openRelatedExpensesTimeframesDialog().afterClosed().pipe(
        filter(resp => !!resp),
        switchMap(timeframeIdsForRelatedExpenses => {
          this.showLoader();
          return this.relatedExpensesService.addRelatedExpenses$(timeframeIdsForRelatedExpenses);
        }),
        finalize(() => this.hideLoader())
      ).subscribe({
        next: (updatedExpense: ExpenseDO) => {
          this.currentState.name = this.prevState.name = updatedExpense.name;
          this.formData.controls.name.setValue(this.currentState.name);
          this.currentState.relationGroupId = this.prevState.relationGroupId = this.relatedExpensesService.relationGroupId;
        },
        error: error => this.onError(error, messages.ADD_RELATED_EXPENSES_ERROR_MSG)
      });
    }
  }

  viewAllRelatedExpenses(): void {
    this.relatedExpensesService.viewAllRelatedExpenses().then(
      () => this.handleCancelAction()
    );
  }

  reviewExpense(expenseId: number): void {
    this.appRoutingService.closeStack();
    setTimeout(() => this.appRoutingService.openInvoiceReview(expenseId));
  }
}
