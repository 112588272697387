// row-storage.service.ts
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { getRequestOptions } from '@shared/utils/http-request.utils';
import { UserManager } from './user/services/user-manager.service';
import { BudgetDataService } from './dashboard/budget-data/budget-data.service';
import { API_V2_URL } from '@common-lib/lib/injection-tokens/url.tokens';


export interface CompanySettingsDO {
  id: number;
  company_id: number;
  param_key: string;
  param_value: string;
  component: string;
  description: string;
  allowed_values: string;
  editable: boolean;
  allow_delete: boolean;
  crd: string;
  upd: string;
}



@Injectable({
  providedIn: 'root'
})
export class CompanySettingsStorageService {

  SECRET_KEY = 'customFieldsSecretKey';
  private readonly budgetDataService = inject(BudgetDataService);
  private readonly apiV2Url = inject(API_V2_URL);

  constructor(private http: HttpClient, private readonly userManager: UserManager) { }

  // This encryption is only for hiding the actual data from the user by encoding, it is not secure so don't use it for storing sensitive data
  encrypt(data: any): string {
    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), this.SECRET_KEY).toString();
    return encryptedData;
  }

  decrypt(encryptedData: string): any {
    try {
      const bytes = CryptoJS.AES.decrypt(encryptedData, this.SECRET_KEY);
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      return decryptedData;
    } catch (error) {
      console.error('Error decrypting data:', error);
      return null;
    }
  }

  isDataValid(encryptedData: string): boolean {
    try {
      const decryptedData = this.decrypt(encryptedData);
      // Here you can add additional checks to validate the decrypted data if needed
      return decryptedData !== null;
    } catch (error) {
      return false;
    }
  }

  saveRowToSessionStorage(key: string, data: any): void {
    const encryptedData = this.encrypt(data);
    sessionStorage.setItem(key, encryptedData);
  }

  getRowFromSessionStorage(key: string): any {
    const encryptedData = sessionStorage.getItem(key);
    if (encryptedData && this.isDataValid(encryptedData)) {
      const decryptedData = this.decrypt(encryptedData);
      return decryptedData;
    } else {
      return null;
    }
  }

  fetchRowFromAPI(key: string, companyId: number): Observable<any> {
 
    return this.http.get(`${this.apiV2Url}` + 'company_settings/', getRequestOptions({ company: companyId })).pipe(
      catchError(error => {
        console.error('Failed to fetch row:', error);
        return of(null);
      })
    );
  }

  getSettings(key: string, companyId?: number): Observable<any> {
    let rowData = this.getRowFromSessionStorage(key);
    const userInfo = this.userManager.getCurrentUser();
    if(!companyId && userInfo) {
      companyId = this.budgetDataService.selectedBudgetStorage.getSelectedCompany(userInfo.id);
    }

    if (!rowData) {
      console.log("Fetching the new company settings from API")
      return this.fetchRowFromAPI(key, companyId).pipe(
        map((data: any) => {
          if (data) {
            this.saveRowToSessionStorage(key, data);
          }
          return data;
        })
      );
    } else {
      return of(rowData);
    }
  }

  removeRowFromSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
  }
}
