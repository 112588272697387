import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Subject } from 'rxjs';
import { Configuration } from 'app/app.constants';
import { UserManager } from 'app/user/services/user-manager.service';
import { CompanyDataService } from 'app/shared/services/company-data.service';
import { HistoryService } from 'app/shared/services/history.service';
import { UserDataService } from 'app/shared/services/user-data.service';
import { BudgetDataService } from 'app/dashboard/budget-data/budget-data.service';
import { Budget } from 'app/shared/types/budget.interface';
import { AppRoutingService } from 'app/shared/services/app-routing.service';
import { BudgetSegmentAccess } from 'app/shared/types/segment.interface';
import { FilterManagementService } from './components/filters/filter-services/filter-management.service';
import { CompanyUserDO } from '@shared/types/company-user-do.interface';

@Component({
  selector: 'header-navigation',
  templateUrl: './header-navigation.component.html',
  styleUrls: ['./header-navigation.component.scss']
})
export class HeaderNavigationComponent implements OnInit, OnDestroy {
  currentUser: CompanyUserDO;
  activeRoute = '';
  companyId: number;
  editPermission: boolean;
  routes = this.configuration.ROUTING_CONSTANTS;
  currentBudget: Budget;
  pagesWithoutFilters = [
    this.routes.HOME,
    this.routes.TEAM,
    this.routes.BUDGET_SETTINGS,
    this.routes.CURRENCIES,
    this.routes.EXPENSE_TYPES,
    this.routes.GL_CODES,
    this.routes.METRIC_FUNNELS,
    this.routes.INTEGRATION_SETTINGS,
    this.routes.SHARED_COST_RULES,
    this.routes.TAGS,
    this.routes.IMPORT_DATA,
    this.routes.CAMPAIGN_MAPPINGS,
  ];
  hideFilters = false;

  private availableSegments: BudgetSegmentAccess[];
  private availableSegmentIds: number[];
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly router: Router,
    private readonly userManager: UserManager,
    public readonly configuration: Configuration,
    private readonly companyDataService: CompanyDataService,
    private readonly historyService: HistoryService,
    private readonly userDataService: UserDataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly budgetDataService: BudgetDataService,
    private readonly appRoutingService: AppRoutingService,
    public readonly filterManagementService: FilterManagementService
  ) {}

  ngOnInit() {
    combineLatest([
      this.userManager.currentCompanyUser$
        .pipe(
          filter(user => !!user),
          takeUntil(this.destroy$),
          tap(currentUser => this.currentUser = currentUser)
        ),
      this.companyDataService.selectedCompany$
        .pipe(
          filter(cmp => !!cmp),
          tap(company => this.companyId = company.id)
        ),
      this.budgetDataService.selectedBudget$
        .pipe(
          filter(budget =>
            budget && (!this.currentBudget || this.currentBudget.id !== budget.id)
          ),
          tap(budget => this.currentBudget = budget)
        ),
      this.budgetDataService.segmentList$.pipe(
        tap(segmentList => {
          this.availableSegments = segmentList;
          this.availableSegmentIds = this.availableSegments.map(segment => segment.id);
        })
      )
      ])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500)
      )
      .subscribe(() => this.loadHistoryItems());

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(this.defineActivePath);

    this.userDataService.editPermission$
      .pipe(takeUntil(this.destroy$))
      .subscribe(editPermission => this.editPermission = editPermission);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToDashboard() {
    this.navigateTo([this.routes.DASHBOARD])
  }

  navigateToCalendar() {
    this.navigateTo([this.routes.CALENDAR]);
  }

  navigateTo(route) {
    this.router.navigate(route);
  }

  loadHistoryItems() {
    this.historyService.loadObjectOperationLogs(this.currentUser.user, this.currentBudget.id, this.availableSegmentIds);
    this.historyService.loadHistory(this.companyId, this.currentUser.user, this.currentBudget.id);
  }

  defineActivePath = () => {
    this.activeRoute = this.appRoutingService.getCurrentActivatedPrimaryRoutePath();
    this.hideFilters = this.pagesWithoutFilters.includes(this.activeRoute);
  };
}
