import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import {
  HierarchySelectConfig,
  HierarchySelectItem,
  HierarchySelectValue
} from '../hierarchy-select/hierarchy-select.types';


@Component({
  selector: 'parent-hierarchy-select',
  templateUrl: './parent-hierarchy-select.component.html',
  styleUrls: ['./parent-hierarchy-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentHierarchySelectComponent implements OnChanges {
  @Input() items: HierarchySelectItem[];
  @Input() selectedValue: HierarchySelectValue = null;
  @Input() config: Partial<HierarchySelectConfig>;
  @Input() disabled: boolean;
  @Input() disableOptionCentering = true;
  @Input() showTriggerIcon = false;
  @Output() onChange = new EventEmitter<HierarchySelectValue>();
  @Output() onClose = new EventEmitter<void>();

  public selectConfig: HierarchySelectConfig = {
    fieldLabel: 'Parent',
    withSearch: true,
    emptyValueLabel: 'No parent',
    searchPlaceholder: 'Search'
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.config && this.config) {
      this.selectConfig = {
        ...this.selectConfig,
        ...this.config
      };
    }
  }
}
