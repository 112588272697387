<div class="external-box d-flex ai-center"
     [matTooltip]="tooltipText"
     [ngClass]="{ 'disabled-state': !uniqueId }"
     matTooltipClass="dark-theme-tooltip above"
     matTooltipPosition="above"
     (click)="copy()">
  <fa-icon class="finger-icon" [icon]="['fas', 'fingerprint']"></fa-icon>
  <div class="content d-flex fd-column">
    <span class="external-label">{{ getObjectName }}</span>
    <span class="external-value">{{ uniqueId }}</span>
  </div>
  <fa-icon class="copy-icon" [icon]="['fas', 'copy']"></fa-icon>
</div>
