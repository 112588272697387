import { inject, Injectable } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AppConfigService } from '@common-lib/services/app-config.service';
import { AppConfig } from './app-config.interface';

export type ObjectsIconConfig = Record<string, IconProp>;
export enum PermissionType {
  ADMIN = 'admin',
  READ = 'read',
  READ_AND_WRITE = 'read/write',
}

@Injectable({
  providedIn: 'root'
})
export class Configuration {
  private readonly appConfigService: AppConfigService<AppConfig> = inject(AppConfigService);

  public register_url = this.appConfigService.config?.register_url;
  public xlsx_import_service_url = this.appConfigService.config?.xlsx_import_service_url;
  public db_export_service_url = this.appConfigService.config?.db_export_service_url;
  public export_report_url = this.appConfigService.config?.xlsx_export_report_url;
  public attachments_service_url = this.appConfigService.config?.attachments_service_url;
  public invoice_processing_service_url = this.appConfigService.config?.invoice_processing_service_url;
  public salesforce_service_url = this.appConfigService.config?.salesforce_service_url;
  public google_ads_service_url = this.appConfigService.config?.google_ads_service_url;
  public facebook_ads_service_url = this.appConfigService.config?.facebook_ads_service_url;
  public linkedin_service_url = this.appConfigService.config?.linkedin_service_url;
  public hubspot_service_url = this.appConfigService.config?.hubspot_service_url;
  public exchange_rate_service_url = this.appConfigService.config?.exchange_rate_service_url;
  public envDomain = this.appConfigService.config?.env_domain?.toLowerCase();
  public region = this.appConfigService.config?.region;
  public customIdentityProviders = this.appConfigService.config?.custom_idps;
  public readonly churnZeroAppKey = this.appConfigService.config?.churn_zero_app_key;
  public readonly importDataKnowledgeLink = this.appConfigService.config?.import_data_knowledge_url;
  public readonly exportDataKnowledgeLink = this.appConfigService.config?.export_data_knowledge_url;
  public readonly costSharingRulesKnowledgeLink = this.appConfigService.config?.cost_sharing_rules_knowledge_url;
  public readonly managePageKnowledgeLink = this.appConfigService.config?.manage_page_knowledge_url;
  public readonly pendoApiKey = this.appConfigService.config?.pendo_api_key;

  public email_warning = 'We recommend you use your work email. It will be easier later if you want to collaborate with co-workers using Planful and will also enable you to benchmark your company.';
  public month = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
  public full_month = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
  public full_month_object = { 'Jan': 'January', 'Feb': 'February', 'Mar': 'March', 'Apr': 'April', 'May': 'May', 'Jun': 'June', 'Jul': 'July', 'Aug': 'August', 'Sep': 'September', 'Oct': 'October', 'Nov': 'November', 'Dec': 'December' };
  public quarter = [ 'Q1', 'Q2', 'Q3', 'Q4' ];
  public year = [ 'Year' ];
  public permissions = [ {'id': PermissionType.READ,  'name': 'Read Only'}, {'id': PermissionType.READ_AND_WRITE, 'name': 'Read/Write'} ];
  public expenseStatusNames = {
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted',
  };
  public campaignStatusNames = {
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted',
  };
  public programStatusNames = {
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted',
  };
  public goalStatusNames = {
    active: 'Active',
    inactive: 'Inactive',
    deleted: 'Deleted',
  };
  public planNames = {
    freePlan: 'free_plan',
    soloPlan: 'solo_plan',
    proPlan: 'pro_plan',
    compPlan: 'comp_plan',
    unlimitedPlan: 'unlimited_plan'
  };
  public companyPlanType = {
    basicPlan: 'basic_plan'
  };
  public modes = [ 'Planned' , 'Committed' , 'Closed' ];
  public statusNames = {
      available: 'Available',
      reserved: 'Reserved',
      planned: 'Planned',
      committed: 'Committed',
      closed: 'Closed',
      actual: 'Actual',
      overBudget: 'Over Budget',
      underBudget: 'Under Budget',
      overSpend: 'Overspend',
      remainingAllocated: 'Remaining Allocated'
  };
  public statusFields = {
      available: 'available',
      reserved: 'reserved',
      planned: 'planned',
      committed: 'committed',
      closed: 'closed',
      actual: 'actual',
      overBudget: 'overdue',
      underBudget: 'under_budget',
      overSpend: 'overspend',
      remainingAllocated: 'remaining_allocated',
      budgetRemainingAmount: 'budget_remaining_amount'
  };
  public filter_status = [
    { id: 4, itemName: this.statusNames.available },
    { id: 5, itemName: this.statusNames.remainingAllocated },
    { id: 1, itemName: this.statusNames.planned },
    { id: 2, itemName: this.statusNames.committed },
    { id: 3, itemName: this.statusNames.closed }
  ];

  public goal_small = 29;
  public camp_small = 29;
  public prog_small = 26;

  public user_type_list = [
      { 'id': 1, 'user_type': 'Admin' },
      { 'id': 1, 'user_type': 'User' }
  ];

  // Regex constants
  public FIRST_LAST_NAME_REGEX = new RegExp(/^[a-zA-Z '-]+$/);
  public EMAIL_REGEX = new RegExp(/^[a-zA-Z0-9._+-]+[a-zA-Z0-9_+-]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/);

  public ALPHANUMERIC_REGEX = new RegExp(/^[A-Za-z0-9]{1,100}$/);
  public FILENAME_REGEX = new RegExp(/^[0-9a-zA-Z_\-.()\]\[ ]+$/);

  public MAX_TEXT_INPUT_LENGTH = 128;
  public MAX_TEXT_INPUT_LENGTH_EXTERNAL_INTEGRATION = 412;
  public MAX_TAG_NAME_LENGTH = 45;
  public MAX_VENDOR_NAME_LENGTH = 45;

  public acceptedAttachmentFileExtensions = '.csv,.png,.jpg,.jpeg,.tiff,.tif,.pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx';

  public ROUTING_CONSTANTS = {
    LOGIN: 'login',
    LOGOUT: 'logout',
    CHANGE_PASSWORD: 'change_password',
    REGISTER: 'register',
    REGISTER_SUCCESS: 'register_success',
    FORGOT_PASSWORD: 'forgot_password',
    SSO_NO_ACCESS: 'sso_no_access',
    PLAN_DETAIL: 'plan_detail',
    QUICK_START: 'quick_start',
    BUDGET_SETTINGS: 'budget_settings',
    TEAM: 'team',
    CUSTOM_FIELDS: 'custom_fields',
    USER: 'user',
    CURRENCIES: 'currencies',
    INTEGRATION_SETTINGS: 'integration_settings',
    CAMPAIGN_MAPPINGS: 'campaign_mappings',
    SHARED_COST_RULES: 'shared_cost_rules',
    METRIC_FUNNELS: 'metric_funnels',
    IMPORT_DATA: 'import_data',
    TAGS: 'tags',
    GL_CODES: 'gl_codes',
    EXPENSE_TYPES: 'expense_types',
    ATTRIBUTES_TAGS: 'attributes-tags',
    DASHBOARD: 'dashboard',
    SPENDING_MANAGEMENT: 'spending',
    CALENDAR: 'calendar',
    MANAGE_COMPANY: 'manage_company',
    SEARCH: 'search',
    CREATE_GOAL: 'goal/create',
    CREATE_CAMPAIGN: 'campaign/create',
    CREATE_PROGRAM: 'program/create',
    CREATE_EXPENSE: 'expense/create',
    CREATE_GOAL_METRIC: 'goal_metric/create',
    CREATE_CAMPAIGN_METRIC: 'campaign_metric/create',
    CREATE_PROGRAM_METRIC: 'program_metric/create',
    HOME: 'home',
    MANAGE_PAGE: 'manage',
    MANAGE_CEG_PAGE: 'manage_ceg',
    SSO: 'enable_sso'
  };

  public PFM_COMPANY_SETTINGS_KEY = "PFM_COMPANY_SETTINGS"

  public ROUTER_OUTLETS = {
    DETAILS: 'details',
    DRAWER: 'drawer',
    DRAWER_STACK: 'drawerStack',
    PRIMARY: 'primary'
  };

  public DEFAULT_ROUTE = this.ROUTING_CONSTANTS.HOME;
  public QUERY_PARAMS = {
    RETURN_URL: 'returnUrl'
  };
  public SEARCH_PREFIXES = {
    'TAG': 'Tag:'
  };
  public LOCKED_TIMEFRAME_MESSAGE =
    'Timeframe is closed and values can\'t be modified. '
    + 'To do this, an Admin or Budget Owner must re-open the timeframe in the Budget Tab.';

  public DISABLE_SHOWING_ADD_NEW_CURRENCY_NOTIFICATION = 'disableShowingAddNewCurrencyNotification';

  public OBJECT_TYPES = {
    goal: 'Goal',
    campaign: 'Campaign',
    childCampaign: 'ChildCampaign',
    program: 'Program',
    expenseGroup: 'ExpenseGroup',
    expense: 'Expense',
    invoiceReview: 'Invoice',
    metric: 'Metric',
    segment: 'Segment',
    segmentsGroup: 'SegmentsGroup', // TODO: remove segmentsGroup
    segmentGroup: 'SegmentGroup',
    sharedCostRule: 'SharedCostRule',
    tag: 'Tag',
    invoice: 'Invoices'
  };

  public favoriteFilterTooltip = 'Favorite this filter to automatically apply it whenever you open this budget. This setting applies only to you.';
  public removeFilterTooltip = 'Remove filter';

  public sharedSegmentName = 'Shared';
  public defaultSegmentName = 'Default Segment';

  public static defaultObjectTypeName = 'Other';

  public expenseStatusesDescriptions = {
    planned: 'This describes expenses that are anticipated, with specific cost estimates',
    committed: 'This describes expenses that have a contractual commitment, but which haven’t yet been invoiced',
    closed: 'This describes expenses that have been invoiced, charged on a credit card, or accrued by accounting',
  };

  public budgetStatusesDescriptions = {
    available: 'This is the amount of money left in your budget after subtracting all expenses and remaining allocated amounts',
    negativeAvailable: 'A Negative Available amount means that you cannot spend all remaining allocated funds and stay within budget',
    remainingBudget: 'This is equal to the budget minus all expenses (but before subtracting Remaining Allocated).',
    remainingAllocated: 'This is money that you have allocated to a campaign or expense group, but have not yet spent (via expenses).',
    reserved: 'This is budget that you have earmarked for a campaign or expense group. Reserved budget is consumed (reduced) by expenses associated with that campaign or expense group',
    overBudget: 'If the total of your planned, committed, and closed expenses exceeds your budget allocation, the overspend will be displayed as Over Budget',
    underBudget: 'If you officially close a quarter or a month, Under Budget indicates how much of your budget went unspent in that timeframe',
    expensesFromOtherSegments: 'These expenses are included above, but are billed to other segments'
  };

  public attributesAndTags = {
    emptyListText: (type: string, article: 'a' | 'an') => `Create ${article} ${type} and get organized`,
    emptySearchPrimaryText: (type: string) => `No matching ${type} found`,
    emptySearchSecondaryText: (type: string) => `Try a different keyword or create a new ${type}.`,
  }

  public attributesAndTagsLabel = {
    'GL_CODE': 'GL Code',
    'TAG': 'Tag',
    'EXPENSE_TYPE': 'Expense Type',
    'VENDOR': 'Vendor',
    'CAMPAIGN_TYPE': 'Campaign/Expense Group Type',
    'GOAL_TYPES': 'Goal Types',
    'GOAL_TYPE': 'Goal Type'
  }

  public expenseTypesDescriptions = {
    created: 'Expense Type created successfully.',
    updated: 'Expense Type updated successfully.',
    deleted: 'Expense Type deleted successfully.',
    error: 'Enter a name that is not already taken',
    enableTooltip : 'Disabled attributes remain visible but can not be used in new campaigns, expenses, etc. You can re-enable them at any time.'
  };

  public vendorsDescriptions = {
    created: 'Vendor created successfully.',
    updated: 'Vendor updated successfully.',
    deleted: 'Vendor deleted successfully.',
    error: 'Enter a name that is not already taken',
    enableTooltip : 'Disabled attributes remain visible but can not be used in new campaigns, expenses, etc. You can re-enable them at any time.'
  };

  public glCodesDescriptions = {
    created: 'GL code created successfully.',
    updated: 'GL code updated successfully.',
    deleted: 'GL code deleted successfully.',
    nameError: 'Enter a unique GL code',
    descriptionError: 'Enter GL code description',
    enableTooltip : 'Disabled attributes remain visible but can not be used in new campaigns, expenses, etc. You can re-enable them at any time.'
  }

  public tagsDescriptions = {
    created: 'Tag created successfully.',
    updated: 'Tag updated successfully.',
    deleted: 'Tag deleted successfully.',
    error: 'Enter a name that is not already taken',
    enableTooltip : 'Disabled attributes remain visible but can not be used in new campaigns, expenses, etc. You can re-enable them at any time.'
  };

  public GroupTypeDescriptions = {
    created: 'Campaign/Expense group created successfully.',
    updated: 'Campaign/Expense group updated successfully.',
    deleted: 'Campaign/Expense group deleted successfully.',
    error: 'Enter a name that is not already taken',
    enableTooltip : 'Disabled attributes remain visible but can not be used in new campaigns, expenses, etc. You can re-enable them at any time.'
  };

  public goalTypesDescriptions = {
    created: 'Goal Type created successfully.',
    updated: 'Goal Type updated successfully.',
    deleted: 'Goal Type deleted successfully.',
    error: 'Enter a name that is not already taken',
    enableTooltip : 'Disabled attributes remain visible but can not be used in new campaigns, goals, etc. You can re-enable them at any time.'
  };

  public customFieldsDescriptions = {
    enableSwitchTooltip : 'Turn on to make the custom field required for every assigned Campaign, Expense Group, or Expense',
    errorCheckbox: 'Select at least one item',
    errorEmptyDropdownValue: 'Select at least one value',
    errorDuplicateDropdownValue: 'That value is taken. Try another.',
    errorEmptyFieldName: 'Enter a field name',
    errorDuplicateFieldName: 'That name is taken. Try another.',
    errorSpecialCharacters: 'No special character allowed',
    deleteTitle: 'Permanently Delete Custom field?',
    deleteContent: 'You will permanently lose the Integrations custom field from everywhere it is used.',
    typeInfo: 'Once published, you cannot switch from a multi-select dropdown to a single-select.',    
    draftSaveConfirmation: 'Do you want to save as draft and go?',    
    publishConfirmation: 'Do you want to publish and go?',    
    textUnsaveChanges: 'You have unsaved changes',
    checkboxLabelText: 'Apply this Custom Field to (up to 4 custom fields can be applied for each object)'
  };

  public defaultDraggableComponentValues = {
    defaultLabel: 'Dropdown Values',
    defaultAddButtonText: 'New Value',
    defaultplaceHolderText: 'Enter Value',
    errorDuplicateDropdownValue: 'That value is taken. Try another.',
    errorSpecialCharacters: 'No special character allowed',
    errorEmptyDropdownValue: 'Enter a dropdown value name'
  };

  public LS_KEY_NAVIGATE_TO_LOGIN = 'navigateToLogin';
  public NEGATIVE_ID = -1;

  public iconsConfig: ObjectsIconConfig = {
    [this.OBJECT_TYPES.goal]: ['fas', 'bullseye-arrow'],
    [this.OBJECT_TYPES.campaign]: ['far', 'rocket-launch'],
    [this.OBJECT_TYPES.program]: ['fas', 'briefcase'],
    [this.OBJECT_TYPES.expenseGroup]: ['fas', 'briefcase'],
    [this.OBJECT_TYPES.expense]: ['fas', 'coins'],
    [this.OBJECT_TYPES.segment]: ['far', 'chart-pie-simple'],
    [this.OBJECT_TYPES.segmentsGroup]: ['far', 'chart-pie'],  // TODO: remove segmentsGroup
    [this.OBJECT_TYPES.segmentGroup]: ['far', 'chart-pie'],
    [this.OBJECT_TYPES.childCampaign]: ['far', 'rocket-launch'],
    [this.OBJECT_TYPES.metric]: ['fas', 'chart-line'],
  };

  public detailsHeaderClass: { [key: string]: string } = {
    [this.OBJECT_TYPES.goal]: 'goal-header-bg',
    [this.OBJECT_TYPES.campaign]: 'campaign-header-bg',
    [this.OBJECT_TYPES.program]: 'program-header-bg',
    [this.OBJECT_TYPES.expense]: 'expense-header-bg',
    [this.OBJECT_TYPES.metric]: 'metric-header-bg',
  };

  public getShortMonthName(fullName: string): string {
    const ind = this.full_month.findIndex(fn => fn === fullName);
    if (ind < 0) {
      console.error(`String "${fullName}" can't be converted to short month name`);
      return fullName;
    }
    return this.month[ind];
  }

}
