import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  BudgetObjectsData,
  ExpensePageBudgetData,
  ExpensePageCompanyData,
  HierarchyViewMode,
  SidebarHierarchyOption
} from 'app/expenses/types/expense-page.type';
import { createRegExpFromString } from 'app/shared/utils/common.utils';
import { SpendingSidebarOptionsProviderService } from './spending-sidebar-options-provider.service';
import { ExpensesService } from '@shared/services/backend/expenses.service';
import { LocalStorageService } from '@common-lib/services/local-storage.service';
import { EXPENSE_PAGE_SIDEBAR_VIEW_MODE } from '@shared/constants/modes.constant';

@Injectable()
export class SpendingSidebarService {

  private readonly hierarchyMode = new BehaviorSubject<HierarchyViewMode>(HierarchyViewMode.Segment);
  private readonly selectedSidebarOptionsMap = new BehaviorSubject<Record<number | string, SidebarHierarchyOption>>({});
  private readonly selectedViewOptions = new BehaviorSubject<SidebarHierarchyOption[]>([]);
  private readonly expenseCountsMap = new BehaviorSubject<Record<number, number>>({});
  private readonly isAllSelected = new BehaviorSubject<boolean>(false);
  private readonly isLoading = new BehaviorSubject<boolean>(false);
  private readonly dragEndAction = new Subject();
  private readonly remoteSelect = new Subject<SidebarHierarchyOption>();

  public readonly hierarchyMode$ = this.hierarchyMode.asObservable();
  public readonly selectedSidebarOptionsMap$ = this.selectedSidebarOptionsMap.asObservable();
  public readonly viewOptions$ = this.selectedViewOptions.asObservable();
  public readonly isAllSelected$ = this.isAllSelected.asObservable();
  public readonly isLoading$ = this.isLoading.asObservable();
  public readonly expenseCountsMap$ = this.expenseCountsMap.asObservable();
  public readonly dragEndAction$ = this.dragEndAction.asObservable();
  public readonly remoteSelect$ = this.remoteSelect.asObservable();

  private markedAsSelected: Map<number | string, boolean> = new Map();

  constructor(
    private readonly optionsProvider: SpendingSidebarOptionsProviderService,
    private readonly expensesService: ExpensesService,
  ) { }

  get sidebarHierarchyMode(): HierarchyViewMode {
    return this.hierarchyMode.getValue();
  }

  get viewOptionsValue(): SidebarHierarchyOption[] {
    return this.selectedViewOptions.getValue();
  }

  get selectedOptionsMapValue(): Record<number | string, SidebarHierarchyOption> {
    return this.selectedSidebarOptionsMap.getValue();
  }

  get hasSelection(): boolean {
    return !!Object.keys(this.selectedOptionsMapValue).length;
  }

  get isAllSelectedValue(): boolean {
    return this.isAllSelected.getValue();
  }

  get isHierarchy(): boolean {
    return this.sidebarHierarchyMode === HierarchyViewMode.Segment
      || this.sidebarHierarchyMode === HierarchyViewMode.Campaign
      || this.sidebarHierarchyMode === HierarchyViewMode.Goal;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading.next(isLoading);
  }

  setAllSelected(isSelected: boolean): void {
    if (isSelected === this.isAllSelectedValue) { return; }
    this.isAllSelected.next(isSelected);
    if (isSelected) {
      this.selectAllOptions();
    } else {
      this.clearSelection();
    }
  }

  setSideBarHierarchyMode(mode: HierarchyViewMode): void {
    this.hierarchyMode.next(mode);
    this.clearSelection();
    LocalStorageService.addToStorage(EXPENSE_PAGE_SIDEBAR_VIEW_MODE, mode);
  }

  get hierarchyModeValue(): HierarchyViewMode {
    return this.hierarchyMode.getValue();
  }

  isMarkedAsSelected(id: number | string): boolean {
    return this.markedAsSelected.get(id);
  }

  updateViewOptions(
    companyData: ExpensePageCompanyData,
    budgetData: ExpensePageBudgetData,
    budgetObjects: BudgetObjectsData
  ): void {
    var resultOptions = this.optionsProvider.getViewOptionsForMode(
      this.hierarchyModeValue, companyData, budgetData, budgetObjects, this.selectedOptionsMapValue
    );
    const options = resultOptions.filter(function (el) {
      return el !== null;
    });
    const hierarchicalViews = [HierarchyViewMode.Segment, HierarchyViewMode.Goal, HierarchyViewMode.Campaign];
    const isHierarchicalView = hierarchicalViews.includes(this.hierarchyModeValue);
    const selectedOptions = Object.values(this.selectedOptionsMapValue);
    const singleSelectedItem = selectedOptions.length === 1 ? selectedOptions[0] : null;

    if (isHierarchicalView && singleSelectedItem?.level > 1) {
      this.openSelectedHierarchy(options, singleSelectedItem);
    }

    this.selectedViewOptions.next(options);
    this.setIsLoading(false);
  }

  openSelectedHierarchy(options: SidebarHierarchyOption[], selectedOption: SidebarHierarchyOption): void {
    const targetIndex = options.findIndex(option => option.id === selectedOption.id && option.objectType === selectedOption.objectType);
    let processedLevel = selectedOption.level;
    const indexesForExpanding = [];
    for (let i = targetIndex; i > 0; i--) {
      if (options[i].level < processedLevel) {
        processedLevel -= 1;
        indexesForExpanding.push(i);
      }
      if (options[i].level === 1) {
        break;
      }
    }
    indexesForExpanding
      .sort()
      .forEach(i => this.toggleCollapsed(i, false, options));
  }

  toggleCollapsed(index: number, stayOpened: boolean, options: SidebarHierarchyOption[]): void {
    const selectedOptions = options || this.viewOptionsValue;
    const parentOption = selectedOptions[index];
    parentOption.collapsed = stayOpened ? !stayOpened : !parentOption.collapsed;
    for (let i = index + 1; i < selectedOptions.length; i++) {
      if (selectedOptions[i].level <= parentOption.level) { break; }
      if (selectedOptions[i].level === parentOption.level + 1) {
        selectedOptions[i].visible = !parentOption.collapsed;
      }
      if (!selectedOptions[i].collapsed) {
        this.toggleCollapsed(i, false, selectedOptions);
      }
    }
    if (!options) {
      this.selectedViewOptions.next(selectedOptions);
    }
  }

  toggleSelected(option: SidebarHierarchyOption, index?: number): void {
    this.markedAsSelected.clear();
    this.setAllSelected(false);
    const isSelected = this.selectedOptionsMapValue[option.id];
    let selectedMap;
    if (!isSelected) {
      selectedMap = { [option.id]: option };
      if (option.hasChildren) {
        this.markAsSelected(index);
      }
    } else {
      selectedMap = {};
    }
    this.selectedSidebarOptionsMap.next(selectedMap);
  }

  searchOptions(searchQuery: string): void {
    const isSearchActive = searchQuery.length > 2;

    const viewOptions = this.viewOptionsValue;
    const regEx = createRegExpFromString(searchQuery, 'i');
    viewOptions.forEach(option => {
      option.searchHidden = isSearchActive && !regEx.test(option.title);
    });

    if (isSearchActive && this.isAllSelectedValue) {
      this.setAllSelected(false);
    }
    this.selectedViewOptions.next(viewOptions);
  }

  onDragEndAction(action: null): void {
    this.dragEndAction.next(action);
  }

  updateExpenseCounts(queryObject): Observable<Record<number, number>> {
    return this.getCountsByGroup(this.hierarchyModeValue, queryObject).pipe(
      tap(expenseCounts => this.expenseCountsMap.next(expenseCounts))
    );
  }

  remoteSelectOption(option: SidebarHierarchyOption): void {
    this.remoteSelect.next(option);
  }

  validateMarkedAsSelected(): void {
    for (const option of Object.values(this.selectedOptionsMapValue)) {
      if (option.hasChildren) {
        const index = this.viewOptionsValue.findIndex(o => o.id === option.id);
        if (index > -1) {
          this.markAsSelected(index);
        }
      }
    }
  }

  private selectAllOptions(): void {
    const selectedMap = {};
    this.viewOptionsValue.forEach(option => {
      if (option.level === 1) {
        selectedMap[option.id] = option;
      }
    });
    this.selectedSidebarOptionsMap.next(selectedMap);
  }

  private markAsSelected(index: number, prevClear = false): void {
    if (prevClear) {
      this.markedAsSelected.clear();
    }
    const selectedOptions = this.viewOptionsValue;
    const option = selectedOptions[index];
    for (let i = index + 1; i < selectedOptions.length; i++) {
      if (selectedOptions[i].level <= option.level) { break; }
      this.markedAsSelected.set(selectedOptions[i].id, true);
    }
    this.selectedViewOptions.next(selectedOptions);
  }

  private clearSelection(): void {
    this.isAllSelected.next(false);
    this.markedAsSelected.clear();
    if (Object.keys(this.selectedOptionsMapValue).length) {
      this.selectedSidebarOptionsMap.next({});
    }
  }

  private getCountsByGroup(sideBarHierarchyMode: HierarchyViewMode, queryObject): Observable<Record<number, number>> {
    const ViewModeToCountGroup = {
      [HierarchyViewMode.Segment]: 'segment',
      [HierarchyViewMode.Goal]: 'goal',
      [HierarchyViewMode.GlCode]: 'gl_code',
      [HierarchyViewMode.Vendor]: 'vendor',
      [HierarchyViewMode.Status]: 'status',
      [HierarchyViewMode.Source]: 'source',
      [HierarchyViewMode.Timeframe]: 'timeframe',
    };

    return sideBarHierarchyMode in ViewModeToCountGroup
      ? this.expensesService.getCountsByGroup({
        group: ViewModeToCountGroup[sideBarHierarchyMode],
        ...queryObject
      })
      : of({});
  }
}
