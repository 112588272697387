<article class="expense-details details-page-wrapper">
  <loader [isVisible]="isLoading"></loader>
  <drawer-header
    [detailsFormGroupData]="formData"
    [actions]="menuActions"
    [budgetList]="budgets"
    [isReadOnlyMode]="isReadOnlyMode"
    [hasUnsavedChanges]="unsavedChangesFlag"
    [objectHierarchy]="hierarchy"
    [nameFormControl]="fdNameControl"
    [maxObjectNameLength]="budgetObjectDetailsManager.maxObjectNameLength"
    [objectType]="objectType"
    [objectLabel]="objectLabel"
    [createdDate]="currentState?.created"
    [updatedDate]="currentState?.updated"
    [isInvoiceDrawer]="isInvoiceDrawer"
    [headerBackgroundClass]="headerBackgroundClass[objectType]"
    [isCEGBudget]="cegStatusEnabled"
    [disableSaveAndCloseButton]="isCustomFieldsEnabledForExpense && !isCustomFieldsFormValid"
    (budgetToMoveSelected)="handleBudgetToMoveSelected($event)"
    (cancel)="handleCancelAction()"
    (save)="submitChanges(handleSaveAction.bind(this))"
    (saveAndNew)="submitChanges(handleSaveAndNewAction.bind(this))"
    (saveAndClose)="submitChanges(handleSaveAndCloseAction.bind(this))"
  ></drawer-header>
  <div class="unverified-box" *ngIf="isExpenseUnverified && !isInvoiceDrawer">
    <div class="holder">
      <expense-verified-icon
        [size]="32"
      ></expense-verified-icon>
      <div class="info-holder">
        <span class="title">The expense is unverified.</span>
        <p>Ensure this expense is drawing down from the budget accurately by verifying its details.</p>
      </div>
      <button *ngIf="invoiceLiveTracking.hasInvoiceAccess$ | async" mat-button color="primary" (click)="reviewExpense(objectId)">Review</button>
    </div>
  </div>
  <div class="details-content" [formGroup]="formData">
    <details-timestamps
      *ngIf="cegStatusEnabled"
      class="mt-1"
      [created]="currentState?.created"
      [updated]="currentState?.updated"></details-timestamps>
    <input type="hidden" formControlName="isVerified">
    <div class="details-panel">
      <div class="amounts-row"
      [ngClass]="{'expense-less-status-mode': cegStatusEnabled}">
        <div class="details-form-field planned-field"
              highlightField
              [isSplitedInput]="true"
              [values]="cegStatusEnabled ? [fdCurrency, fdSourceActualAmount] : [fdCurrency]"
              [fields]="cegStatusEnabled ? [StateField.CurrencyCode, StateField.SourceActualAmount] : [StateField.CurrencyCode]">
          <div class="icon-container">
            <fa-icon [icon]="['fas', 'sack-dollar']"></fa-icon>
          </div>
          <mat-select class="currency-select"
                      formControlName="currencyCode"
                      (valueChange)="objectCurrencyChanged($event)">
            <mat-option *ngFor="let item of currencyList" [value]="item.currency">
              {{item.currency}}
            </mat-option>
          </mat-select>

          <mat-form-field appearance="outline" floatLabel="always" class="p-form-field-outline"  *ngIf="!cegStatusEnabled">
            <mat-label>Planned Amount</mat-label>
            <input matInput
                   formControlName="sourceAmount"
                   class="text-left"
                   required
                   currencyMask
                   [options]="currencyMaskOptions"
                   (blur)="handleSourceAmountChange()"/>

            <mat-hint *ngIf="currentState?.currencyCode !== companyCurrencyCode && convertedAmount" align="end">
              <span class="mr-1">{{ companyCurrencyCode }}:</span>
              <span>{{ convertedAmount | formatBudget }}</span>
            </mat-hint>
          </mat-form-field>
          <ng-template [ngTemplateOutlet]="actualAmountRef" *ngIf="cegStatusEnabled"></ng-template>
        </div>
        <div class="details-form-field"
              highlightField
              [values]="[fdSourceActualAmount]"
              [fields]="[StateField.SourceActualAmount]"
              *ngIf="!cegStatusEnabled">
          <ng-container [ngTemplateOutlet]="actualAmountRef"></ng-container>
        </div>

        <ng-template #actualAmountRef>
          <mat-form-field appearance="outline" class="p-form-field-outline">
            <mat-label>Actual Amount</mat-label>
            <input matInput
                   formControlName="sourceActualAmount"
                   [required]="false"
                   class="text-left"
                   currencyMask
                   [options]="currencyMaskOptions"
                   (blur)="handleSourceActualAmountChange()"/>
            <mat-hint *ngIf="currentState?.currencyCode !== companyCurrencyCode && convertedActualAmount"
                      align="end"
                      [ngClass]="{ 'status-less-mode-hint': cegStatusEnabled }">
              <span class="mr-1">{{ companyCurrencyCode }}:</span>
              {{ convertedActualAmount | formatBudget }}
            </mat-hint>
          </mat-form-field>
        </ng-template>
        <div class="details-form-field" *ngIf="(fdStatus !== expenseAllocationMode.Planned) && !cegStatusEnabled">
          <mat-form-field appearance="outline" class="p-form-field-outline is-disabled">
            <mat-label>Difference</mat-label>
            <input matInput
                   readonly
                   tabindex="-1"
                   [disabled]="isReadOnlyMode"
                   [ngClass]="{'color-danger': difference < 0}"
                   [class]="utClasses.difference"
                   [value]="difference | formatBudget"/>
            <mat-hint *ngIf="currentState?.currencyCode !== companyCurrencyCode && convertedDifference" align="start">
              {{ convertedDifference | formatBudget }}
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="details-form-field w-50"
            highlightField
            [values]="[fdBudgetAllocationId]"
            [fields]="[StateField.BudgetAllocationId]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'calendar-alt']"></fa-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Timeframe</mat-label>
          <mat-select [panelClass]="'timeframe-select'" [placeholder]="'Timeframe *'" formControlName="budgetAllocationId" (valueChange)="updateConvertedAmounts()" required>
            <mat-select-trigger *ngIf="currentTimeframe?.locked">
              <fa-duotone-icon [icon]="['fad', 'lock-alt']"  class="mr-025 selected-lock"></fa-duotone-icon> {{currentTimeframe?.name}}
            </mat-select-trigger>
            <mat-option *ngFor="let item of budgetTimeframes" [value]="item.id" [disabled]="item.locked">
              <fa-duotone-icon [icon]="['fad', 'lock-alt']" *ngIf="item?.locked" class="mr-025"></fa-duotone-icon>
              {{ item.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- status -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdStatus]"
            [fields]="[StateField.Mode]"
            *ngIf="!cegStatusEnabled">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'wave-pulse']" class="size-17"></fa-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Status</mat-label>
          <mat-select [placeholder]="'Status *'" formControlName="status" (valueChange)="handleStatusUpdate($event)" required>
            <mat-select-trigger>
              <span [class]="'dot ' + fdStatus?.toLowerCase()" [ngClass]="{'status-ceg': cegStatusEnabled}"></span>
              {{ formData.controls['status'].value }}
            </mat-select-trigger>
            <mat-option *ngFor="let item of contextExpenseStatusList" [value]="item.id">
              <span [class]="'dot ' + item.iconCssClass" [ngClass]="{'status-ceg': cegStatusEnabled}"></span>
              <span>{{ item.name }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="details-panel">
      <!-- glCode -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdGlCode]"
            [fields]="[StateField.GlCode]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'barcode-alt']" class="size-17"></fa-icon>
        </div>
        <ng-container *ngIf="cegStatusEnabled">
        <disabled-flag-mat-select
          class="p-form-field-outline no-validate"
          [labelName]="'GL Code'"
          [isRequired]="false"
          [formData]="formData"
          [optionTitle]="['name', 'description']"
          [control]="'glCode'"
          [addNullOption]="true"
          [nullOptionTitle]="'No GL Code'"
          [value]="formData.controls['glCode'].value"
          [itemList]="glCodes"
        ></disabled-flag-mat-select>
        </ng-container>
        <ng-container *ngIf="!cegStatusEnabled">
        <mat-form-field appearance="outline" class="p-form-field-outline no-validate">
          <mat-label>GL Code</mat-label>
          <mat-select [placeholder]="'GL Code'" formControlName="glCode" [panelClass]="'reset-panel-width'">
            <mat-select-trigger> {{ glCodeActiveName }}</mat-select-trigger>
            <mat-option [value]="null">No GL code</mat-option>
            <mat-option *ngFor="let item of glCodes" [value]="item.id">
              {{ item.name + ' - ' + item.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        </ng-container>
      </div>
      <!-- poNumber -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdPoNumber]"
            [fields]="[StateField.PoNumber]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'file-alt']"></fa-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>PO #</mat-label>
          <input matInput
                 formControlName="poNumber"
                 type="text"
                 blurOnEnterKeydown
                 maxlength="500"
                 trim>
          <mat-error *ngIf="formData.controls['poNumber'].hasError('maxlength')">
            Po Number should be less 500 symbols
          </mat-error>
        </mat-form-field>
      </div>

      <!--Vendor Name-->
    <div class="details-form-field w-50">
      <div class="icon-container">
        <fa-icon [icon]="['fas', 'user-hard-hat']" class="size-22"></fa-icon>
      </div>

      <!-- For Old world -->
      <ng-container *ngIf="!cegStatusEnabled ">
      <mat-form-field appearance="outline" class="p-form-field-outline no-validate">
        <mat-label>Vendor Name</mat-label>
        <input
          matInput
          formControlName="vendorName"
          matAutocompletePosition="below"
          [maxLength]="maxVendorNameLength"
          [matAutocomplete]="auto"
          autoSizeInput [includeBorders]="true" [setParentWidth]="true" [includePadding]="true" [minWidth]="52"
          (blur)="handleVendorChange()"
        />
      </mat-form-field>
      <mat-autocomplete
        #auto="matAutocomplete"
        autoActiveFirstOption
        [panelWidth]="'auto'"
        [displayWith]="getVendorName"
      >
        <mat-option *ngFor="let item of autocompleteVendors | async"
                    [value]="item"
                    [disabled]="item.isNew && item.name.length > maxVendorNameLength"
        >
          <ng-container *ngIf="item.isNew">
            Add "{{ item.name }}" <span class="max-length-counter">{{ item.name.length + '/' + maxVendorNameLength }}</span>
          </ng-container>
          <ng-container *ngIf="!item.isNew">{{ item.name }}</ng-container>
        </mat-option>
      </mat-autocomplete>
      </ng-container>
      <!--For new world -->
      <ng-container *ngIf="cegStatusEnabled ">
      <disabled-flag-mat-select
        class="p-form-field-outline no-validate"
        [labelName]="'Vendor Name'"
        [isRequired]="false"
        [formData]="formData"
        [optionTitle]="['name']"
        [control]="'vendorId'"
        [addNullOption]="true"
        [nullOptionTitle]="'No Vendor'"
        [value]="formData.controls['vendorId'].value"
        [itemList]="vendors"
      ></disabled-flag-mat-select>
      </ng-container>
    </div>

      <!-- invoiceNumber -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdInvoiceNumber]"
            [fields]="[StateField.InvoiceNumber]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'file-invoice-dollar']"></fa-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Invoice Number</mat-label>
          <input matInput
                 formControlName="invoiceNumber"
                 type="text"
                 blurOnEnterKeydown
                 maxlength="500"
                 trim>
          <mat-error *ngIf="formData.controls['invoiceNumber'].hasError('maxlength')">
            Invoice Number should be less 500 symbols
          </mat-error>
        </mat-form-field>
      </div>

      <!-- Source -->
      <drawer-read-only-input
        class="details-form-field w-50"
        inputTitle="Source"
        iconRotation="90"
        icon="plug"
        iconType="fas"
        [objectSource]="currentState?.source"></drawer-read-only-input>
      <!-- Public ID -->
      <div class="details-form-field w-50">
        <unique-id [uniqueId]="currentState?.externalId"
                   tooltipText="Click to copy ID"
                   label="Public ID"
                   [objectType]="objectType"></unique-id>
      </div>

      <!-- ownerId -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdOwnerId]"
            [fields]="[StateField.OwnerId]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'user-crown']"></fa-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline">
          <mat-label>Owner</mat-label>
          <mat-select
            [placeholder]="'Owner *'"
            formControlName="ownerId"
            required
            (selectionChange)="handleOwnerChange($event)"
            [panelClass]="'reset-panel-width'"
          >
            <mat-option *ngFor="let item of ownerOptions" [value]="item.id">
              {{item.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formData.controls['ownerId'].hasError('required')">
            Owner is required
          </mat-error>
        </mat-form-field>
      </div>
      <!-- typeId -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdTypeId]"
            [fields]="[StateField.TypeId]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'martini-glass-citrus']"></fa-icon>
        </div>
        <ng-container *ngIf="cegStatusEnabled">
        <disabled-flag-mat-select
          class="p-form-field-outline no-validate"
          [labelName]="'Expense Type'"
          [isRequired]="true"
          [formData]="formData"
          [optionTitle]="['name']"
          [control]="'typeId'"
          [addNullOption]="false"
          [value]="formData.controls['typeId'].value"
          [itemList]="expenseTypes"
          [hasError]="formData.controls['typeId'].hasError('required')"
          [errorString]="'Expense type is required'"
        ></disabled-flag-mat-select>
        </ng-container>
        <ng-container *ngIf="!cegStatusEnabled">
          <mat-form-field appearance="outline" class="p-form-field-outline">
            <mat-label>Expense Type</mat-label>
            <mat-select [placeholder]="'Expense Type *'" formControlName="typeId" required [panelClass]="'reset-panel-width'">
              <mat-option *ngFor="let item of expenseTypes" [value]="item.id">
                {{item.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="formData.controls['typeId'].hasError('required')" class="no-wrap">
              Expense type is required
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>

      <!-- deliveryDate -->
      <div class="details-form-field w-50"
            highlightField
            [values]="[fdDeliveryDate]"
            [fields]="[StateField.DeliveryDate]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'truck']" class="size-17"></fa-icon>
        </div>
        <mat-form-field appearance="outline" class="p-form-field-outline date-picker">
          <mat-label>Delivery Date</mat-label>
          <input matInput [matDatepicker]="pickerDeliveryDate" formControlName="deliveryDate" autocomplete="off" (focus)="$event.target.select()" (mousedown)="pickerDeliveryDate.open()" />
          <mat-datepicker #pickerDeliveryDate></mat-datepicker>
        </mat-form-field>
      </div>
      <!-- segment -->
      <div class="details-form-field w-50"
            [ngClass]="{ 'segment-details-form-field': !!fdLocation }"
            highlightField
            [values]="[currentSegmentId, currentSharedCostRuleId]"
            [fields]="[StateField.SegmentId, StateField.SharedCostRuleId]">
        <div class="icon-container">
          <fa-icon [icon]="['far', 'chart-pie-simple']"></fa-icon>
        </div>
        <hierarchy-select
          [items]="allowedSegmentSelectItems"
          [selectControlExternal]="segmentControl"
          [config]="selectSegmentsConfig"
          [placeholder]="'Segment *'"
          [fixedSelectWidth]="true"
          [groupSelectionAllowed]="false"
          [disabled]="isReadOnlyMode || !!fdLocation && cegStatusEnabled"
          (onChange)="handleSegmentChanged($event)"
        ></hierarchy-select>
        <span *ngIf="!!fdLocation && cegStatusEnabled">Inherited from parent</span>
      </div>
    </div>
    
      <!-- Custom Fields -->
  <div class="details-panel custom-fields-container"
   *ngIf="!isInvoiceDrawer && cegStatusEnabled && isCustomFieldsEnabledForExpense && customFieldConfigs.length">
    <h2 class="panel-floating-title">Custom Fields</h2>
    <div class="custom-field-el" *ngFor="let config of customFieldConfigs; let i = index">
      <custom-field-el
        [config]="config"
        [control]="form.controls['dropdown_' + i]" 
        (stateChanged)="onDropdownStateChanged($event, i)"
        >
      </custom-field-el>
    </div>
  </div>


    <div class="details-panel">
      <!-- Parent -->
      <div class="details-form-field w-100 parent"
            highlightField
            [values]="[
              fdLocation | objectId : programObjectType,
              fdLocation | objectId : campaignObjectType
            ]"
            [fields]="[StateField.Program, StateField.Campaign]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'sitemap']" class="size-17"></fa-icon>
        </div>
        <parent-hierarchy-select
          [items]="locationItems"
          [disabled]="fdLocationControl?.disabled || isReadOnlyMode"
          [selectedValue]="fdLocation"
          [disableOptionCentering]="false"
          [showTriggerIcon]="true"
          [config]="{ selectPanelClass: 'details-parent-select', fieldAppearance: 'outline' }"
          (onChange)="handleParentSelectionChange($event)"
        >
        </parent-hierarchy-select>
      </div>
      <!--- Current Related -->
      <div *ngIf="!isInvoiceDrawer" class="details-form-field w-50">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'link']"></fa-icon>
        </div>
        <div class="related-expenses ai-center">
          <div class="expenses-number mr-05">{{ relatedExpensesNumber }} Related expenses</div>
          <link-button *ngIf="relatedExpensesService.relationGroupId" (onClick)="viewAllRelatedExpenses()">View all</link-button>
        </div>
      </div>
      <!-- Add Related -->
      <div *ngIf="!isInvoiceDrawer" class="details-form-field w-50 ai-center">
        <add-object-link
          [text]="'Add Related Expense(s)'"
          [handler]="onAddRelatedExpensesClick.bind(this)"
          [disabled]="isAddingRelatedExpensesDisabled"></add-object-link>
      </div>
    </div>

    <div class="details-panel">
      <!-- tags -->
      <div class="details-form-field w-100">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'tags']"></fa-icon>
        </div>
        <tags-control
          #tagsControl
          class="mb-1"
          fieldAppearance="outline"
          [tagsList]="currentState?.tagMappings"
          [autocompleteItems]="tagsManager.autocompleteItems"
          [editOnly]="true"
          [editForbidden]="isReadOnlyMode"
          (onAddTag)="addTag($event)"
          (onCreateTag)="createTag($event)"
          (onRemoveTag)="removeTag($event)"
        >
        </tags-control>
      </div>
      <!-- attachments -->
      <div class="details-form-field w-100">
        <div class="icon-container">
          <fa-icon [icon]="['far', 'paperclip']"></fa-icon>
        </div>
        <attachments-list
          class="mb-1"
          [disableInput]="!currentState?.objectId"
          [objectTypeName]="objectType"
          [isReadOnlyMode]="isReadOnlyMode"
          [showAddIcon]="false"
          [attachments]="attachmentsManager.attachments"
          [isInvoiceDrawer]="isInvoiceDrawer"
          (onAttachFiles)="handleFileAttached($event)"
          (onDeleteAttachment)="handleFileDelete($event)"
          (onDownloadAttachment)="handleFileDownload($event)"
        ></attachments-list>
      </div>
      <!-- notes -->
      <div class="details-form-field w-100"
            highlightField
            [values]="[fdNotes]"
            [fields]="[StateField.Notes]">
        <div class="icon-container">
          <fa-icon [icon]="['fas', 'clipboard']"></fa-icon>
        </div>
        <linkified-text formControlName="notes" label="Notes" appearance="outline"></linkified-text>
      </div>
    </div>
  </div>
</article>
