<div
  class="budget-allocation-cell"
  [ngClass]="{
    'gestures-allowed': editable && allowGestures,
    'dnd-allowed': editable && allowDnd,
    'is-focused': isFocused,
    'is-editable': editable,
    'is-disabled': disabled,
    'billion-cap-mode': allocatedValue >= billionCapLimit,
    'is-dragged': isDragged,
    'is-drag-over': isDragOver,
    'with-difference': showDifference,
    'global-drag-started': globalDragStarted
  }"
  [plDroppable]="isDropAllowed"
  (dropOn)="handleDropOn()"
  (dragEnter)="handleDragEnter()"
  (dragLeave)="handleDragLeave()"
>
  <div
    *ngIf="showDifference && differenceValue !== 0"
    class="difference"
    budgetAllocationActionTooltip
    [actionTooltipContext]="actionTooltipContext"
    [matTooltip]="disabled ? disabledDiffTooltip : null"
    matTooltipPosition="above"
    matTooltipClass="dark-theme-tooltip simple multi-line"
    [withDnd]="isDragAllowed"
    [showTooltip]="allowGestures && editable && !disabled && !isDragged && (isDragAllowed || hasRemainingBudget)"
    [class.overspend]="differenceValue < 0"
    [plDraggable]="isDragAllowed"
    (dragStart)="handleDragStart()"
    (dragEnd)="handleDragEnd()"
    (dblclick)="handleDoubleClick()"
  >
    {{ (differenceValue < 0 ? -differenceValue : differenceValue) | number: decimalPipeFormat }}
  </div>

  <input
    [ngClass]="{ 'input-animation-disabled': inputAnimationDisabled }"
    type="text"
    class="value-input"
    currencyMask
    [selectTextOnFocus]="true"
    [maxLength]="maxLength"
    [disabled]="!editable || disabled"
    [attr.title]="allocatedValue | number: decimalPipeFormat"
    [(ngModel)]="allocatedValue"
    [options]="currencyMaskOptions"
    (focus)="setFocus(true)"
    (blur)="handleBlur()"
    blurOnEnterKeydown
  >
</div>
