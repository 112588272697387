import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppDataLoader } from '../../app-data-loader.service';
import { UtilityService } from '../../shared/services/utility.service';
import { CompanyDataService } from '../../shared/services/company-data.service';
import { BudgetDataService } from '../../dashboard/budget-data/budget-data.service';
import { Configuration } from '../../app.constants';
import { filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Budget } from '../../shared/types/budget.interface';
import { selectionTypes, customFieldList, customFieldOptions, customFieldDetails } from './custom-fields.type';
import { FormControl, Validators } from '@angular/forms';
import { CustomFieldsAdminService } from './custom-fields.service';
import { CompanySettingsStorageService } from '../../company-settings-storage.service';
import { CustomFieldStatusAndCountDO, CustomFieldsService } from '../../budget-object-details/components/custom-fields/custom-field.service';
import { DraggableItem } from '../../shared/components/draggable-options/draggable-options.component';
import { BudgetObjectDialogService } from '../../shared/services/budget-object-dialog.service';

@Component({
  selector: 'custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
  providers: [AppDataLoader]
})
export class CustomFieldsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  companyId: number;
  private selectedBudget: Budget = null;
  appliedList = ['Campaign', 'Expense', 'ExpenseGroup'];
  selectionType: selectionTypes;
  enableSwitchTooltip: string;
  listOfCustomFields = [];
  copyListOfCustomFields = [];
  selectedField: customFieldDetails;
  initialSelectedField: customFieldDetails;
  dropdownOptions: DraggableItem[] = [];
  compnayCFSettings: any;
  maxCountForCampaign: number;
  maxCountForProgram: number;
  maxCountForExpense: number;
  customFieldCountData: CustomFieldStatusAndCountDO;
  initialFieldCountData: CustomFieldStatusAndCountDO;
  isDraftedField = false;
  isChangeInField = false;
  disabledCreateButton = false;
  disabledSaveButton = false;
  isLoading = false;
  isMultiSelected = false;
  isCreateNewField = false;
  maxCharFieldName = 20;
  maxCharFieldDes = 120;
  fieldNameControl = new FormControl('');
  fieldDesControl = new FormControl('', [
    Validators.maxLength(this.maxCharFieldDes)
  ]);
  isDropdownOptionRequired = false;
  defaultOption: DraggableItem = null;
  inUpdateState = true;
  isErrorInDropdownValue = false;
  isCheckboxSelected = true;
  isFieldNameValid = true;
  isFieldNameEmpty = false;
  checkForSpecialCharacter = false;
  isDropdownValueValid = true;
  isTypeChanged = false;

  emptyCustomField: customFieldDetails = {
    name: '',
    description: '',
    for_campaign: false,
    for_program: false,
    for_expense: false,
    is_mandatory: false,
    is_draft: false,
    type: 'Single',
    customfieldoptions: []
  };

  constructor(
    private readonly utilityService: UtilityService,
    private readonly companyDataService: CompanyDataService,
    private readonly appDataLoader: AppDataLoader,
    private readonly budgetDataService: BudgetDataService,
    private readonly config: Configuration,
    private readonly service: CustomFieldsAdminService,
    private readonly companySettingsStorageService: CompanySettingsStorageService,
    private readonly CFService: CustomFieldsService,
    private readonly dialogService: BudgetObjectDialogService
  ) {}

  ngOnInit(): void {
    this.initializeCompanyAndBudgetData();
    this.loadCompanySettings();
    this.appDataLoader.init();

    this.enableSwitchTooltip = this.config.customFieldsDescriptions.enableSwitchTooltip;

    this.CFService.getCFStatus().subscribe(data => {
      this.customFieldCountData = data;
      this.initialFieldCountData = { ...this.customFieldCountData };
      this.updateCustomFieldButtonStatus();
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateCustomFieldButtonStatus() {
    const totalUsedCustomFields = this.customFieldCountData.cfCamapaignCount
        + this.customFieldCountData.cfExpenseCount
        + this.customFieldCountData.cfProgramCount;

    const maxAllowedCustomFields = this.maxCountForCampaign
      + this.maxCountForProgram
      + this.maxCountForExpense;

    if(totalUsedCustomFields === maxAllowedCustomFields){          
      this.disabledCreateButton = true;          
    }
    else{
      if(!this.isCreateNewField){
        this.disabledCreateButton = false;
      }
    }
  }

  private initializeCompanyAndBudgetData() {
    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null),
        switchMap(cmp => this.utilityService.expenseTypeListPropertyChanged$.pipe(map(() => cmp.id)))
      )
      .subscribe(companyId => {
        this.companyId = companyId;
      });

    this.companyDataService.selectedCompany$
      .pipe(
        takeUntil(this.destroy$),
        filter(cmp => cmp != null)
      )
      .subscribe(cmp => {
        this.companyId = cmp.id;
        this.companyDataService.loadCompanyData(this.companyId);
        this.getCustomFieldList(this.companyId);
      });

    this.budgetDataService.selectedBudget$
      .pipe(
        tap(newSelectedBudget => this.onSelectNewBudget(newSelectedBudget)),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: error => this.utilityService.handleError(error)
      });
  }

  private loadCompanySettings() {
    const key = this.config.PFM_COMPANY_SETTINGS_KEY;
    this.companySettingsStorageService.getSettings(key).subscribe(data => {
      data.forEach(item => {
        switch (item.param_key) {
          case 'MaxCustomFieldsForCampaigns':
            this.maxCountForCampaign = Number(item.param_value);
            break;
          case 'MaxCustomFieldsForExpenses':
            this.maxCountForExpense = Number(item.param_value);
            break;
          case 'MaxCustomFieldsForPrograms':
            this.maxCountForProgram = Number(item.param_value);
            break;
          default:
            break;
        }
      });
    });
  }

  get remainingNameCharacters(): number {
    return this.fieldNameControl.value.length;
  }
  get remainingDesCharacters(): number {
    return this.fieldDesControl.value.length;
  }

  private handleError(error: any): void {
    this.utilityService.handleError(error);
    this.isLoading = false;
    this.utilityService.showLoading(false);
  }

  private getCustomFieldList(companyId: number, callback?: () => void, fromUpdate=false): void {
    this.isLoading = true;
    this.utilityService.showLoading(true);
    this.service.getCFList({ company: companyId })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: customFieldList[]) => {
          this.listOfCustomFields = res;
          this.copyListOfCustomFields = [...this.listOfCustomFields];
          this.resetCustomField(!fromUpdate);
          this.isLoading = false;
          this.utilityService.showLoading(false);
          // Check if a callback function is provided
          if (callback) {
            callback();
          }
        },
        error: error => {
          this.handleError(error);
        }
      });
  }

  private resetCustomField(forOption = true, cancelAction = false) {
    if (this.listOfCustomFields.length === 0) {
        this.createNewField();
    } else {
        if (cancelAction) {
            this.handleCancelAction(forOption);
        } else {
            this.resetToDefault(forOption);
        }
        this.resetDefaultChanges();
    }    
    this.updateCustomFieldButtonStatus();
    this.isTypeChanged = false;
}

private handleCancelAction(forOption) {
    const updatedFieldIndex = this.listOfCustomFields.findIndex(field => field.id === this.selectedField.id);
    if (updatedFieldIndex !== -1) {
        this.selectedField = this.listOfCustomFields[updatedFieldIndex];
        this.getCFOptionList(this.listOfCustomFields[updatedFieldIndex].id);
    } else {
        this.resetToDefault(forOption);
    }
}

private resetToDefault(forOption) {
    if (forOption) {
        this.getCFOptionList(this.listOfCustomFields[0].id);
    }
    this.listOfCustomFields[0] = { ...this.copyListOfCustomFields[0] };
    this.selectedField = this.listOfCustomFields[0];
}


  private resetDefaultChanges() {    
    this.validateTypeSelection();
    this.isChangeInField = false;
    this.checkForDraftedField();
    this.isCreateNewField = false;
    this.isCheckboxSelected = true;
    this.isFieldNameValid = true;
  }

  setChangeInField() {
    this.isChangeInField = this.checkForChanges();
    this.handleValidation();
  }

  checkForChanges(): boolean {
    return JSON.stringify(this.selectedField) !== JSON.stringify(this.initialSelectedField);
  }

  getCFOptionList(id: number): void {
    this.service.getCFDetails(id, { company: this.companyId })
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: customFieldOptions[]) => {
          const formattedOptions = res.map(option => ({
            is_default: option.is_default,
            option_value: option.option_value,
            id: option.id
          }));
          this.selectedField.customfieldoptions = formattedOptions;
          this.initialSelectedField = { ...this.selectedField };
          this.dropdownOptions = res.map(option => ({
            id: option.id,
            value: option.option_value
          }));
          this.isDropdownOptionRequired = this.selectedField.is_mandatory;
          const getdefaultOption = this.selectedField.customfieldoptions.find(option => option.is_default);
          this.defaultOption = getdefaultOption ? { id: getdefaultOption.id, value: getdefaultOption.option_value } : null;
          this.isChangeInField = false;
          this.isLoading = false;
          this.utilityService.showLoading(false);
        },
        error: (error) => {
          this.handleError(error);
        }
      });
  }

  handleValidation(isUpdate = false): boolean {
    const hasInvalidChanges = () => {
      return this.selectedField?.customfieldoptions?.length === 0
        || !this.isFieldNameValid
        || this.isErrorInDropdownValue
        || !this.isCheckboxSelected
        || !this.isDropdownValueValid;
    };

    if (this.isDraftedField) {
      if (this.selectedField && this.initialSelectedField) {
        return !hasInvalidChanges();
      }
      return true; // Enable button initially for drafts
    } else {
      if (this.selectedField && this.initialSelectedField) {
        if (hasInvalidChanges()) {
          return false;
        }
        if(isUpdate){
          return this.isChangeInField;
        }
        else{
          return this.isChangeInField && !this.compareCheckboxSelection(this.selectedField, this.initialSelectedField);
        }
      }
      return false; // Disable button if fields are not set
    }
  }



  compareCheckboxSelection(obj1: customFieldDetails, obj2: customFieldDetails): boolean {
    return (
      obj1.for_campaign === obj2.for_campaign &&
      obj1.for_program === obj2.for_program &&
      obj1.for_expense === obj2.for_expense
    );
  }


  createCF(data: customFieldDetails, isDraft = false, maintainState = true, callback?: () => void): void {
    const fieldTitle = data.name;
    this.isLoading = true;
    this.utilityService.showLoading(true);
    data.customfieldoptions = data.customfieldoptions.map(option => {
      if (option.id === 0) {
        return {
          is_default: option.is_default,
          option_value: option.option_value
        };
      }
      return option;
    });
    data.company = this.companyId;
    this.service.createCF(data)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          const eventMessage = isDraft ? 'saved as a draft' : 'published'
          this.getCustomFieldList(this.companyId, () => {
            if(maintainState){
              const createdFieldIndex = this.listOfCustomFields.findIndex(field => field.name === fieldTitle);
              
              if (createdFieldIndex !== -1) {              
                  this.selectedField = this.listOfCustomFields[createdFieldIndex];
                  this.getCFOptionList(this.listOfCustomFields[createdFieldIndex].id);
              }
            }            
            
            this.onUpdateCFListEvent(eventMessage, fieldTitle);
            if (typeof callback === 'function') {
              callback();
          }
        });
        },
        error: (error) => {
          this.handleError(error);
        }
      });
  }

  deleteCF(id: number) {
    let fieldTitle = '';
    if(this.initialSelectedField.id === id){
      fieldTitle = this.initialSelectedField.name;
    }
    else{
      fieldTitle = this.listOfCustomFields.find(field => field.id === id).name;
    }
    if (id) {
      this.service.deleteCF(id, { company: this.companyId }).subscribe
        (() => {
          this.onUpdateCFListEvent('deleted', fieldTitle);
          this.getCustomFieldList(this.companyId);
        },
          error => this.handleError(error)
        );
    }
    else {
      this.resetCustomField();
    }
  }

  updateCustomField(isDraft = false,  callback?: () => void, maintainState = true) {
    const fieldTitle = this.selectedField.name;
    const publishingDraft = this.selectedField.is_draft && !isDraft;
    this.selectedField.is_draft = isDraft;
    this.isLoading = true;
    this.utilityService.showLoading(true);
    this.selectedField.customfieldoptions = this.selectedField.customfieldoptions.map(option => {
      if (option.id === 0) {
        return {
          is_default: option.is_default,
          option_value: option.option_value
        };
      }
      return option;
    });
    const fieldToUpdate = { ...this.selectedField };

    this.service.updateCF(this.selectedField.id, this.selectedField)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getCustomFieldList(this.companyId, () => {
            if (maintainState) {
              const updatedFieldIndex = this.listOfCustomFields.findIndex(field => field.id === fieldToUpdate.id);
              if (updatedFieldIndex !== -1) {
                  this.selectedField = this.listOfCustomFields[updatedFieldIndex];
                  this.getCFOptionList(this.listOfCustomFields[updatedFieldIndex].id);
              }
            }
            if (publishingDraft) {
              this.onUpdateCFListEvent('published', fieldTitle);
            }
            else {
              this.onUpdateCFListEvent('updated', fieldTitle);
            }

            if (typeof callback === 'function') {
                callback();
            }
        }, true);
        },
        error: (error) => {
          this.handleError(error);
        }
      });

      
  }

  deleteCustomField(e) {
    this.deleteCF(e);
  }

  onUpdateCFListEvent(event: string, fieldTitle?: string, callback?: () => void) {
    const toastMessage = `Custom field '${fieldTitle}' ${event}`;
    this.utilityService.showToast({ Title: '', Message: toastMessage, Type: 'success' });
    this.updateCustomFieldButtonStatus();
    this.CFService.fetchCustomFieldStatusForSelectedCompany(this.companyId);
    this.checkForDraftedField();
    this.validateTypeSelection();
    if (callback) {
      callback();
    }
  }

  generateUniqueName(baseName: string): string {
    let uniqueName = baseName;
    let counter = 1;

    while (this.listOfCustomFields.some(item => item.name === uniqueName)) {
      uniqueName = `${baseName} ${counter}`;
      counter++;
    }

    return uniqueName;
  }

  validateTypeSelection() {
    if (this.selectedField.type === "Single" || this.selectedField.is_draft) {
        this.isMultiSelected = false;
    } else {
        this.isMultiSelected = true;
    }
  }

  closeTypeInfo() {
    this.isTypeChanged = false;
  }


  resetValidation() {
    this.isFieldNameValid = true;
    this.isErrorInDropdownValue = false;
    this.checkForSpecialCharacter = false;
    this.isFieldNameEmpty = false;
    this.isCheckboxSelected = true;
  }

  handleFieldUpdate = (create, draft, callback) => {
    if (create) {
        // Create new custom field state
        if (this.handleValidation()) {
          this.selectedField.is_draft = true;
            this.createCF(this.selectedField, true, false, () => {
              callback();
            });
        } else {
            return;
        }
    } else if (draft) {
        // Drafted custom field state
        if (this.handleValidation()) {
            this.updateCustomField(true, () => {
              callback();
            }, false);
        } else {
            return;
        }
    } else {
        if (this.handleValidation(true)) {
            this.updateCustomField(false, () => {
              callback();
            }, false);
        } else {
            return;
        }
    }
}

  getDialogDetails() {
    const dialogMessage = this.isDraftedField || this.isCreateNewField
      ? this.config.customFieldsDescriptions.draftSaveConfirmation
      : this.config.customFieldsDescriptions.publishConfirmation;
    const publishButtonLabel = this.isDraftedField || this.isCreateNewField
      ? 'Save as Draft'
      : 'Publish';
  
    return { dialogMessage, publishButtonLabel };
  }

  createNewCustomField() {
    if(this.isChangeInField){      
      const { dialogMessage, publishButtonLabel } = this.getDialogDetails();  
      const discardHandler = () => {        
        const selectFieldIndex = this.listOfCustomFields.findIndex(field => field.id === this.selectedField.id);
        this.listOfCustomFields[selectFieldIndex] = { ...this.initialSelectedField };
        this.customFieldCountData = { ...this.initialFieldCountData };
        this.createNewField();
      };
      const publishHandler = () => {
        this.handleFieldUpdate(this.isCreateNewField, this.isDraftedField, () => {
            this.createNewField();
        }
        );
      };
      this.openConfirmationDialog(dialogMessage, publishButtonLabel, discardHandler, publishHandler);
    }
    else{
      this.createNewField();
    }
  }

  createNewField() {
    this.utilityService.showLoading(true);
    this.isLoading = true;
    this.initialFieldCountData = { ...this.customFieldCountData };
    this.isChangeInField = false;
    this.isTypeChanged = false;

    this.emptyCustomField.name = this.generateUniqueName("New Custom Field");
    this.isCreateNewField = true;
    this.selectedField = { ...this.emptyCustomField };
    this.initialSelectedField = { ...this.emptyCustomField };
    this.disabledCreateButton = true;
    this.dropdownOptions = [{ id: 0, value: '' }];
    this.isMultiSelected = false;
    this.inUpdateState = false;
    this.isDropdownOptionRequired = false;
    this.resetValidation();
    this.isLoading = false;
    this.defaultOption = null;
    this.utilityService.showLoading(false);
  }

  onRadioSelectionChange(e) {
    this.selectedField.type = e;
    this.setChangeInField();
    this.isTypeChanged = true;
  }

  onSelectField(item: any) {
    if(this.selectedField.id === item.id){
      return;
    }

    if (!this.isChangeInField) {
      this.switchToSelectedField(item);
      return;
    }
  
    const { dialogMessage, publishButtonLabel } = this.getDialogDetails();
  
    const discardHandler = () => {
      this.customFieldCountData = { ...this.initialFieldCountData };
      if (!this.isCreateNewField) {
        const selectFieldIndex = this.listOfCustomFields.findIndex(field => field.id === this.selectedField.id);
        this.listOfCustomFields[selectFieldIndex] = { ...this.initialSelectedField };
      } else {
        this.isCreateNewField = false;
      }
      this.switchToSelectedField(item);
    };
  
    const publishHandler = () => {
      this.handleFieldUpdate(this.isCreateNewField, this.isDraftedField, () => {
        this.switchToSelectedField(item);
      }
      );
  }; 
  
  
    this.openConfirmationDialog(dialogMessage, publishButtonLabel, discardHandler, publishHandler);
  }
  
  openConfirmationDialog(dialogMessage: string, publishButtonLabel: string, discardHandler: () => void, publishHandler: () => void) {
    this.dialogService.openConfirmationDialog(
      {
        title: this.config.customFieldsDescriptions.textUnsaveChanges,
        content: dialogMessage,
        submitAction: {
          label: publishButtonLabel,
          handler: publishHandler,
        },
        cancelAction: {
          label: 'Discard',
          handler: discardHandler,
        },
      },
      {
        width: '480px',
      }
    );
  }
  

  switchToSelectedField(item: any) {
    this.utilityService.showLoading(true);
    this.isLoading = true;    
    this.initialFieldCountData = { ...this.customFieldCountData };
    this.isTypeChanged = false;
    this.updateCustomFieldButtonStatus();
    this.resetValidation();
    this.selectedField = item;
    this.initialSelectedField = { ...item };
    this.getCFOptionList(item.id);
    this.setChangeInField();  
    this.resetDefaultChanges();
    this.updateCustomFieldButtonStatus();
  }

  checkIfAnyCheckboxSelected(): void {
    this.isCheckboxSelected = this.selectedField.for_campaign || this.selectedField.for_program || this.selectedField.for_expense;
  }

  handleCheckboxChange(event: any, field: string) {
    this.selectedField[field] = event;
  
      switch (field) {
        case 'for_campaign':
          this.customFieldCountData.cfCamapaignCount += event ? 1 : -1;
          break;
        case 'for_program':
          this.customFieldCountData.cfProgramCount += event ? 1 : -1;
          break;
        case 'for_expense':
          this.customFieldCountData.cfExpenseCount += event ? 1 : -1;
          break;
        default:
          break;
      }
    
  
    this.setChangeInField();
    this.checkIfAnyCheckboxSelected();
  }
  

  onFieldValueChange() {
    const fieldValue = this.selectedField.name.trim();
    const specialCharacterPattern = /[^a-zA-Z0-9\s]/;
    this.checkForSpecialCharacter = false;
    this.isFieldNameEmpty = false;

    if (!fieldValue || fieldValue.trim() === '') {
      this.isFieldNameEmpty = true;
      this.isFieldNameValid = false;
      this.setChangeInField();
      return;
    }

    if (specialCharacterPattern.test(fieldValue)) {
      this.checkForSpecialCharacter = true;
      this.isFieldNameValid = false;
      this.setChangeInField();
      return;
    }

    const isDuplicate = this.listOfCustomFields.some(field =>
      field.name.trim().toLowerCase() === fieldValue.toLowerCase() && field !== this.selectedField
    );
    this.isFieldNameValid = !isDuplicate;
    this.setChangeInField();
  }

  onDescriptionValueChange(): void {
    if (this.selectedField.description) {
      let trimmedValue = this.selectedField.description.trim().replace(/\s{2,}/g, ' ');  
      if (trimmedValue === '') {
        this.selectedField.description = '';
        this.fieldDesControl.setValue('', { emitEvent: false });
      } else {
        const hasTrailingSpace = this.selectedField.description.endsWith(' ') && !trimmedValue.endsWith(' ');  
        if (trimmedValue !== this.selectedField.description || hasTrailingSpace) {
          this.selectedField.description = trimmedValue + (hasTrailingSpace ? ' ' : '');
          this.fieldDesControl.setValue(this.selectedField.description, { emitEvent: false });
        }
      }
    } else {
      this.selectedField.description = '';
      this.fieldDesControl.setValue('', { emitEvent: false });
    }  
    this.setChangeInField();
  }
  

  toggleEnabled(e) {
    this.isDropdownOptionRequired = e.checked;
    this.selectedField.is_mandatory = e.checked;

    if (!this.defaultOption && e.checked) {
      this.updateDefaultItem(this.dropdownOptions[0]);
    }
    if (!e.checked) {
      this.updateDefaultItem(null);
    }
    this.setChangeInField();
  }

  updateCustomFieldOptions(e : DraggableItem[]) {
    if (e.length === 1 && e[0].value === '') {
      e = [];
    }

    const customFieldOptions = e.map((option : DraggableItem ) => {
      return {
        is_default: this.isDefaultValue(option.value, this.defaultOption),
        option_value: option.value,
        id: option.id > 0 ? option.id : 0
      };
    });

    this.selectedField.customfieldoptions = customFieldOptions;
    this.setChangeInField();
  }

  publishDraftCustomField(id) {
    const fieldTitle = this.listOfCustomFields.find(field => field.id === id).name;
    const data = {
      company: this.companyId,
      is_draft: false
    }
    this.service.updateCFStatus(id, data).subscribe(
      () => {
        this.onUpdateCFListEvent('published', fieldTitle)
      },
      error => this.utilityService.handleError(error)
    );

  }

  updateDefaultItem(defaultItem: DraggableItem) {
    this.isDropdownValueValid = !defaultItem 
      ? true 
      : defaultItem.value !== "" || !this.isDropdownOptionRequired;
    
    this.defaultOption = defaultItem;
  
    this.updateCustomFieldOptions(this.dropdownOptions);
    this.setChangeInField();
  }  
  

  handleErrorInDropdownValue(event: { hasDuplicates: boolean, hasSpecialChars: boolean, isEmpty: boolean }) {  
    if (event.hasDuplicates || event.hasSpecialChars || event.isEmpty) {
      this.isErrorInDropdownValue = true;
    } else {
      this.isErrorInDropdownValue = false;
    }
  }

  isDefaultValue(option: string, defaultItem: DraggableItem): boolean {
    return option === defaultItem?.value;
  }

  publishCustomField(isDraft = false) {
    if (this.isCreateNewField) {
      this.createCF(this.selectedField, this.selectedField.is_draft);
    }
    else {
      this.updateCustomField(isDraft);
    }
  }

  saveAsDraft() {
    this.selectedField.is_draft = true;
    this.publishCustomField(this.selectedField.is_draft);
  }

  discardCustomField() {
      this.customFieldCountData = { ...this.initialFieldCountData };
    
    this.isCheckboxSelected = true;
    if(this.isChangeInField){
      const currentIndex = this.listOfCustomFields.findIndex(field => field.id === this.selectedField.id);
      this.listOfCustomFields[currentIndex] = { ...this.initialSelectedField };
    }
    this.resetCustomField(true, true);
  }

  checkForDraftedField() {
    if (this.selectedField.is_draft) {
      this.isDraftedField = true;
      this.inUpdateState = false;
    }
    else {
      this.isDraftedField = false;
      this.inUpdateState = true;
    }
  }

  private onSelectNewBudget(newSelectedBudget: Budget) {
    this.selectedBudget = newSelectedBudget;
    if (newSelectedBudget != null) {
      this.budgetDataService.loadLightCampaigns(
        this.companyId,
        this.selectedBudget.id,
        this.config.campaignStatusNames.active,
        error => this.utilityService.handleError(error)
      );

      this.budgetDataService.loadLightPrograms(
        this.companyId,
        this.selectedBudget.id,
        this.config.programStatusNames.active,
        error => this.utilityService.handleError(error)
      );
    }
  }
}
