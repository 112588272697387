export const integrationProviders = {
  GOOGLE: "Google",
  SALESFORCE: "Salesforce",
  FACEBOOK: "Facebook",
  HUBSPOT: "Hubspot",
  LINKEDIN: "Linkedin"
}

export const messages = {
  UNABLE_TO_INTEGRATE_COMPANY: 'Failed to initialize'
};