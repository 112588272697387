<hierarchy-select
  [items]="items"
  [selectedValue]="selectedValue"
  [config]="selectConfig"
  [placeholder]="'Parent'"
  [disabled]="disabled"
  [disableOptionCentering]="disableOptionCentering"
  [showTriggerIcon]="showTriggerIcon"
  (onChange)="onChange.emit($event?.id || null)"
  (onClose)="onClose.emit($event)"
></hierarchy-select>
