<div class="pfm-datepicker" [ngClass]="size">
  <div class="icon-container" *ngIf="withIcon">
    <fa-icon class="cal-icon" [icon]="[iconType, icon]"></fa-icon>
  </div>
  <mat-form-field
    appearance="outline"
    class="p-form-field-outline"
    style="pointer-events: none"
  >
    <mat-label class="pfm-label">{{ label }}</mat-label>
    <input
      matInput
      [min]="minDate"
      [max]="maxDate"
      [matDatepicker]="picker"
      [disabled]="disabled"
      [(ngModel)]="value"
      [autocomplete]="autocomplete"
      (focus)="$event.target.select()"
      (mousedown)="picker.open()"
      [matDatepickerFilter]="checkIfDateAvailable"
      [required]="isRequired"
      #inputValue="ngModel"
      style="pointer-events: all"
    />
    <mat-datepicker #picker></mat-datepicker>
    <button
      *ngIf="value && !disabled"
      class="clear-icon"
      mat-button
      matSuffix
      style="pointer-events: all"
      (click)="clearDate(); picker.open()"
    >
      <fa-duotone-icon
        [matTooltip]="clearTooltipValue"
        matTooltipClass="dark-theme-tooltip simple multi-line"
        matTooltipPosition="above"
        [icon]="['fad', 'times-circle']"
      ></fa-duotone-icon>
    </button>
    <mat-error *ngIf="isRequired && inputValue.touched"
      >Date is required</mat-error
    >
  </mat-form-field>
</div>
