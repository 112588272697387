import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BudgetObjectSource, BudgetObjectSourceLabels } from '../../types/budget-object-details-state.interface';
import { faFilterList, faRuler } from "@fortawesome/pro-solid-svg-icons";

@Component({
  selector: 'drawer-read-only-input',
  templateUrl: './drawer-read-only-input.component.html',
  styleUrls: ['./drawer-read-only-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerReadOnlyInputComponent implements OnInit {
  @Input() objectSource: BudgetObjectSource;
  @Input() objectValue: String;
  @Input() inputTitle: String;
  @Input() icon: String;
  @Input() iconType: String;
  @Input() iconRotation: String;
  @Input() nonManualEntry: boolean = false;
  @Input() isDuotoneIcon: boolean;
  @Input() isArrayIcon: boolean = true;
  private nonArrayIcon: any;

  protected budgetObjectSourceLabels = BudgetObjectSourceLabels;
  protected defaultSource: string;

  ngOnInit(): void {
    if (!this.nonManualEntry) {
      this.defaultSource = 'Manual entry';
    } else {
      this.defaultSource = '';
    }
    if (!this.isArrayIcon) {
      switch (this.icon) {
        case 'faFilterList':
          this.nonArrayIcon = faFilterList
          break;
        case 'faRuler':
          this.nonArrayIcon = faRuler
          break;
        default:
          break;
      }
    }
  }
}


